import {UrlTenantFinder} from "@surebase/shared-component-library";

export class TenantHelper {
	static getCurrentTenant = (): string => {
		return UrlTenantFinder.findTenant(window.location.host) ?? "surebase";
	};
	
	static isOverstappenTenant = (): boolean => {
		const current = this.getCurrentTenant();
		return current === "overstappen";
	};
	
	static isSurebaseTenant = (): boolean => {
		const current = this.getCurrentTenant();
		return current === "surebase";
	};
	
	static setTitleAndIcon = (): void => {
		const current = this.getCurrentTenant();
		const favIcon: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement("link");
		favIcon.rel = "shortcut icon";
		
		const appleTouch: HTMLLinkElement = document.querySelector("link[rel*='apple-touch-icon']") || document.createElement("link");
		appleTouch.rel = "apple-touch-icon";
		
		const manifest: HTMLLinkElement = document.querySelector("link[rel*='manifest']") || document.createElement("link");
		manifest.rel = "manifest";
		
		if (current === "overstappen") {
			document.title = "Mijn Overstappen";
			favIcon.href = window.location.origin + "/favicon-os.svg";
			appleTouch.rel = window.location.origin + "/logo192-os.png";
			manifest.rel = window.location.origin + "/manifest-os.json";
		} else {
			document.title = "Mijn Surebase";
			favIcon.href = window.location.origin + "/favicon-sb.ico";
			appleTouch.rel = window.location.origin + "/logo192-sb.png";
			manifest.rel = window.location.origin + "/manifest.json";
		}
		
		document.getElementsByTagName("head")[0].appendChild(favIcon);
		document.getElementsByTagName("head")[0].appendChild(appleTouch);
	};
}
