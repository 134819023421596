import {gql} from "@apollo/client";

export const upsertTaskById = gql `
	mutation UpsertTask($input: UpsertTaskByIdInput!) {
		upsertTaskById(input: $input){
			uuid
			errors {
				... on IGraphQlErrorBase {
					message
					type
				},
				... on NotAuthenticatedError {
					reason
				},
				... on NotAuthorizedError {
					reason
					user
					action
					resource
				}
			}
		}
	}
`;

export const mutationCloseTask = gql `
	mutation CloseTask($taskId: UUID!) {
		changeTaskStatus(input: {statusType: D, taskId: $taskId}){
			boolean
		}
	}
`;
