import {gql} from "@apollo/client";

export const GET_CLAIMS = gql`
	query GetClaims($first: Int, $where: ClaimFilterInput, $order: [ClaimSortInput!]) {
		claims(first: $first, where: $where, order: $order) {
			pageInfo {
				hasNextPage
				endCursor
			}
			nodes {
				deleteAfter
				entityType
				fileNumber
				claimNumberCarrier
				claimFileReferenceLossAdjustmentAgency
				dateOfLoss
				mgaBusinessLineType
				mgaSubBusinessLineType
				dateLossReportedToCarrier
				faultClaim
				fallbackAfterDebtDamage
				statusType
				claimSettlementDate
				externalSourceName
				anvaDelete
				id
				version
				creationDateTime
				lastModifiedDateTime
				lastModifiedBy
				krn
				incident {
					entityType
					classType
					injuryOrImpairedHealth
					damageType
					damageTypeDescription
				}
				financial {
					entityType
					fileNumber
					invoiceNumber
					appliedDeductibleAmount
					benefitAmount
					externalSourceName
					externalSourceId
					anvaDelete
				}
				policyDetails {
					entityType
					contractNumber
					contractModificationIdSourceSystem
					refKey
				}
				afdObject {
					entityType
					... on CaravanObject {
						licensePlate
					}
					... on MopedBicycleObject {
						licensePlate
					}
					... on MotorVehicleObject {
						licensePlate
					}
					... on TrailerObject {
						licensePlate
					}
				}
			}
		}
	}`;

