import {Customers} from "../../services/customers/graphql/Customers";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {
	SbButton,
	SbDateTimePicker,
	SbDivider, SbProcessButton,
	SbTextField,
	SbTypography,
	SbTypographyRaw,
	User01Icon,
	XCloseIcon
} from "@surebase/shared-component-library";
import {LocalDate} from "@js-joda/core";
import React, { useState } from "react";
import { Contracts } from "../../services/contracts/graphql/Contracts";
import {TasksDataRepository} from "../../services/tasks/TasksDataRepository";

export interface CustomerReportChangeDialogProps {
	readonly open: boolean;
	readonly onClose: () => void;
	readonly showAlert: (value: (((prevState: boolean) => boolean) | boolean)) => void;
	readonly customer?: Customers.Customer;
	readonly contracts?: Contracts.Contract[];
}

export function CustomerReportChangeDialog(props: CustomerReportChangeDialogProps) {
	const [product, setProduct] = useState<string>("");
	const [productValid, setProductValid] = useState<boolean>(true);
	const [date, setDate] = useState<string>(LocalDate.now().toString());
	const [change, setChange] = useState<string>("");
	const [changeValid, setChangeValid] = useState<boolean>(true);
	
	const open = props.open;
	const onClose = props.onClose;
	const showAlert = props.showAlert;
	
	const customer = props.customer;
	const contracts = props.contracts;
	const contractsToChange = contracts?.map(c => {
		return {
			ContractNumber: c.contractNumber,
			MgaSubBusinessLineTypeDescription: c.mgaSubBusinessLineTypeDescription?.replace(" (Subliem)", "").replace(" (Subliempakket)", "")
		};
	});
	
	function validateForm() {
		let isProductValid: boolean;
		let isChangeValid: boolean;
		
		if (product === undefined || product === null || product === "") {
			setProductValid(false);
			isProductValid = false;
		} else {
			setProductValid(true);
			isProductValid = true;
		}
		
		if (change === undefined || change === null || change === "") {
			setChangeValid(false);
			isChangeValid = false;
		} else {
			setChangeValid(true);
			isChangeValid = true;
		}
		
		return (isProductValid && isChangeValid);
	}
	
	return <Dialog
		open={open}
		onClose={onClose}
		fullWidth={true}
		maxWidth={"md"}
	>
		<DialogTitle style={{paddingBottom: "0px"}}>
			<SbTypography variant={"cardButtonTitle1"}>
				Report a change
			</SbTypography>
		</DialogTitle>
		
		<IconButton
			aria-label="close"
			onClick={onClose}
			sx={(theme) => ({
				position: "absolute",
				right: 8,
				top: 8,
				color: theme.palette.grey[500],
			})}
		>
			<XCloseIcon style={{ color: "#000000" }} />
		</IconButton>
		
		<DialogContent>
			<Stack gap={2}>
				<Stack gap={1}>
					<Stack
						direction={"row"}
						gap={1}
						alignItems={"center"}
					>
						<User01Icon color={"primary"}/>
						
						<SbTypographyRaw
							variant={"cardButtonLabel"}
							color={(theme) => theme.palette.primary.main}
						>
							{customer?.callName} {customer?.surname}
						</SbTypographyRaw>
					</Stack>
					
					<SbDivider/>
				</Stack>
				
				<Stack gap={1}>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<SbTypography
							variant={"detailsContent2"}
						>
							Which product would you like to change?
						</SbTypography>
						
						<FormControl style={{
							width: "50%"
						}}>
							<InputLabel variant={"outlined"} size={"small"}>Maak een keuze:</InputLabel>
							<Select
								size={"small"}
								variant={"outlined"}
								value={product}
								label={"Make a choice:"}
								onChange={(x) => setProduct(x.target.value)}
								onBlur={(event) => setProductValid(event.target.value !== "")}
								error={!productValid}
							>
								{
									contractsToChange?.map((c) => {
										return c.ContractNumber == null || c.MgaSubBusinessLineTypeDescription == null
											? <></>
											: <MenuItem
												key={c.ContractNumber}
												value={c.ContractNumber}
											>
												{c.MgaSubBusinessLineTypeDescription} - {c.ContractNumber}
											</MenuItem>;
									})
								}
							</Select>
						</FormControl>
					</Stack>
					
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<SbTypography
							variant={"detailsContent2"}
						>
							Per when would you want the change to occur?
						</SbTypography>
						
						<SbDateTimePicker
							type={LocalDate}
							format={"DD-MM-YYYY"}
							onChange={(value) => setDate(value.toString())}
							value={LocalDate.parse(date)}
							DatePickerProps={{
								sx: { width: "50%" },
								slotProps: { textField: { size: "small"} }
							}}
						/>
					</Stack>
					
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
					>
						<SbTypography
							variant={"detailsContent2"}
						>
							What would you like to change:
						</SbTypography>
						
						<SbTextField
							variant={"outlined"}
							onChange={(value) => setChange(value)}
							onBlur={(event) => setChangeValid(event.target.value !== "")}
							error={changeValid ? undefined : "error"}
							FieldProps={{ size: "small", multiline: true, minRows: 4, maxRows: 4 }}
							value={change}
							style={{
								width: "50%"
							}}
						/>
					</Stack>
				</Stack>
			</Stack>
		</DialogContent>
		
		<DialogActions style={{padding: "20px 24px", paddingTop: "0px"}}>
			<SbProcessButton
				LoadingButtonProps={{
					startIcon: <></>,
					variant: "contained",
					size: "large",
					onClick: () => {
						if (!validateForm())
							return;
						
						const contract = contracts?.find(c => c.contractNumber === product);
						const intermediary = contract?.party.find(p => p.entityType === "intermediary") as Customers.Intermediary;
						
						if (contract === undefined)
							return;
						
						TasksDataRepository.upsertTaskById(
							"CustomerPortal - Polis wijziging",
							change,
							"WP",
							date,
							[
								{
									customer: {
										refKey: customer?.id,
										relationNumber: customer?.relationNumber
									}
								},
								{
									intermediary: {
										refKey: intermediary.refKey,
										porIdBroker: intermediary.porIdBroker
									}
								}
							],
							[
								{
									contractNumber: contract.contractNumber ?? undefined,
									contractModificationIdSourceSystem: contract.contractModificationIdSourceSystem ?? undefined,
									refKey: contract.id
								}
							]
						).then();
						
						onClose();
						showAlert(true);
					}
				}}
				defaultContent={
					<SbTypography variant={"buttonMedium"}>
						Send
					</SbTypography>
				}
			/>
			<SbButton
				ButtonProps={{
					size: "large",
					color: "inherit",
					variant: "outlined",
					onClick: onClose
				}}
			>
				<SbTypography variant={"buttonMedium"}>
					Cancel
				</SbTypography>
			</SbButton>
		</DialogActions>
	</Dialog>;
}
