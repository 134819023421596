import {gql} from "@apollo/client";

export const GET_DOSSIERS_BY_RELATION_ID = gql`
	query GetDossiers($first: Int, $where: DossierFilterInput) {
		dossiers(first: $first, where: $where) {
			pageInfo {
				endCursor
				hasNextPage
			}
			nodes {
				entityType
				deleteAfter
				fileNumber
				documentDescription
				fileName
				uploadedBy
				uploadedFrom
				attachmentType
				effectiveDate
				party {
					entityType
					refKey
					... on Customer {
						relationNumber
					}
					
					... on Intermediary {
						porIdBroker
					}
				}
				policyDetails {
					refKey
					entityType
					contractModificationIdSourceSystem
					contractNumber
				}
				claim {
					entityType
					refKey
					fileNumber
				}
				afsElement {
					entityType
					... on CustomAttribute {
						customAttributeName
						customAttributeDescription
						valueType
						value
						entityType
					}
				}
				externalSourceName
				anvaDelete
				id
				version
				creationDateTime
				lastModifiedDateTime
				lastModifiedBy
				krn
			}
		}
	}`;

export const GET_CONTRACT_DOSSIERS = gql`
	query GetContractDossiers($where: DossierFilterInput, $order: [DossierSortInput!]) {
		dossiers(where: $where, order: $order) {
			nodes {
				id
				documentDescription
				fileNumber
				fileName
				attachmentType
				effectiveDate
			}
		}
	}`;

export const DOSSIER_FILE = gql`
	query fileByDossierId($id: UUID!) {
		fileByDossierId(id: $id) {
			fileName
			base64File
			contentType
		}
	}`;
