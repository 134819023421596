import {gql} from "@apollo/client";

export const GET_PRINCIPAL_INSURER_LIST = gql`
	query GetCompanyList($ids:[UUID!]!) {
		companyList(ids: $ids) {
			id
			entityType
			processingCode
			refType
			surname
			secondPartOfName
			city
			houseNumber
			houseNumberAddition
			postalCode
			street
			sequenceNumber
			country
			telephoneNumber
			fax
			collectionAccountNumber
			checkAccount
			cityBusinessAddress
			businessPostalCode
			houseNumberAdditionBusinessAddress
			houseNumberBusinessAddress
			streetBusinessAddress
			countryCodeBusinessAddress
			porCompany
			externalSourceId
			anvaDelete
			inactive
			krn
		}
	}`;
