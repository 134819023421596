import {Customers} from "../services/customers/graphql/Customers";
import {formatter, parseLocalDate, PartyDisplayFields} from "@surebase/shared-component-library";

export class CustomerHelper {
	static shortDateFormat: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	};
	
	static buildPartnerFields = (partner: Customers.Partner): PartyDisplayFields => {
		const fields: PartyDisplayFields = {};
		if (partner.initials && partner.initials !== "")
			fields["Initials"] = partner.initials;
		
		if (partner.prefixes && partner.prefixes !== "")
			fields["Prefixes"] = partner.prefixes;
		
		if (partner.surname && partner.surname !== "")
			fields["Surname"] = partner.surname;
		
		if (partner.birthDate && partner.birthDate !== "")
			fields["Birth date"] = formatter.date(parseLocalDate(partner.birthDate), CustomerHelper.shortDateFormat);
		
		return fields;
	};
	
	static buildChildFields = (child: Customers.Child, parentSurname?: string, parentPrefixes?: string): PartyDisplayFields => {
		const fields: PartyDisplayFields = {};
		let surname = child.surname;
		let prefixes = child.prefixes;
		if (!child.surname) {
			if (parentSurname)
				surname = parentSurname;
			
			if (parentPrefixes)
				prefixes = parentPrefixes;
		}
		
		if (child.initials && child.initials !== "")
			fields["Initials"] = child.initials;
		
		if (prefixes && prefixes !== "")
			fields["Prefixes"] = prefixes;
		
		if (surname && surname !== "")
			fields["Surname"] = surname;
		
		if (child.birthDate && child.birthDate !== "")
			fields["Birth date"] = formatter.date(parseLocalDate(child.birthDate), CustomerHelper.shortDateFormat);
		
		return fields;
	};
	
	static getCustomerName = (customer: Customers.Customer | undefined): string => {
		if (!customer)
			return "";
		
		if (customer?.prefixes)
			return `${customer?.initials ?? ""} ${customer?.prefixes ?? ""} ${customer?.surname ?? ""}`;
		else
			return `${customer?.initials ?? ""} ${customer?.surname ?? ""}`;
	};
	
	static getPartnerName = (partner: Customers.Partner | undefined): string => {
		if (!partner)
			return "";
		
		if (partner?.prefixes)
			return `${partner?.initials ?? ""} ${partner?.prefixes ?? ""} ${partner?.surname ?? ""}`;
		else
			return `${partner?.initials ?? ""} ${partner?.surname ?? ""}`;
	};
	
	static getChildName = (child: Customers.Child | undefined): string => {
		if (!child)
			return "";
		
		if (child?.prefixes)
			return `${child?.initials ?? ""} ${child?.prefixes ?? ""} ${child?.surname ?? ""}`;
		else
			return `${child?.initials ?? ""} ${child?.surname ?? ""}`;
	};
}
