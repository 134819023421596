import {
	authorizedByPermission,
	ChevronRightIcon,
	fullSize,
	fullWidth,
	Loading02Icon,
	MessageTextSquare02Icon,
	SbActionsCard,
	SbContractCard,
	SbContractCardProps,
	SbIconButton,
	SbLinkButton,
	SbOrderCard,
	SbOrderCardProps,
	SbTypography,
	SbTypographyRaw,
	TranslationProvider,
	useMobileView,
	User01Icon,
	userSessionProvider,
	useTranslation,
	XCloseIcon,
	SbPolicyHolderCard, SbPolicyHolderCardProps, formatter
} from "@surebase/shared-component-library";
import {permissions} from "../../global/Permissions";
import {Settings} from "../../global/Settings";
import {
	Box,
	CircularProgress,
	Stack, useMediaQuery, useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Contracts} from "../../services/contracts/graphql/Contracts";
import {CustomersDataRepository} from "../../services/customers/graphql/CustomersDataRepository";
import {ContractsDataRepository} from "../../services/contracts/graphql/ContractsDataRepository";
import {OutstandingInvoicesCard} from "../invoices/OutstandingInvoicesCard";
import {DashBoardQuickLinksCard} from "../components/DashBoardQuickLinksCard";
import {PortalDataRepository} from "../../services/portal/graphql/PortalDataRepository";
import {Portal} from "../../services/portal/graphql/Portal";
import {useNavigate} from "react-router-dom";
import {OrganizationDataRepository} from "../../services/organizations/OrganizationDataRepository";
import {Organizations} from "../../services/organizations/Organizations";
import {Invoices} from "../../services/invoices/graphql/Invoices";
import {InvoicesDataRepository} from "../../services/invoices/graphql/InvoicesDataRepository";
import {NotFoundCard, NotFoundCardProps} from "../components/NotFoundCard";
import {ContactCard, ContactCardProps} from "../components/ContactCard";
import {AvailableProductsCard} from "../components/AvailableProductsCard";
import {ProductBanner, ProductBannerProps} from "../components/ProductBanner";
import {TenantHelper} from "../../helpers/TenantHelper";
import {AuthorizationDataRepository} from "../../services/authorization/AuthorizationDataRepository";

export const DashboardPage = authorizedByPermission(() => {
	const theme = useTheme();
	const translate = useTranslation();
	const inMobileView = useMobileView();
	const navigate = useNavigate();
	const [loadingPage, setLoadingPage] = useState<boolean>(true);
	const [loadingContracts, setLoadingContracts] = useState<boolean>(true);
	const [openInvoices, setOpenInvoices] = useState<Invoices.Invoice[]>([]);
	const [availableProducts, setAvailableProducts] = useState<Portal.Product[]>([]);
	const [contracts, setContracts] = useState<Contracts.Contract[]>();
	const [orders, setOrders] = useState<Portal.Order[]>();
	const [brokerContactDetails, setBrokerContactDetails] = useState<ContactCardProps>();
	const [showMobileBrokerDetails, setShowMobileBrokerDetails] = useState<boolean>(false);
	const [policyHolderCardProps, setPolicyHolderCardProps] = useState<SbPolicyHolderCardProps>();
	const [bannerProps, setBannerProps] = useState<ProductBannerProps>();
	const shouldMoveContactCard = useMediaQuery(theme.breakpoints.down("lg"));
	
	useEffect(() => {
		async function loadSurebaseData(): Promise<void> {
			return CustomersDataRepository.getCustomerSummary().then((customerResult) => {
				if (!customerResult) {
					setLoadingPage(false);
					setLoadingContracts(false);
					return;
				}
				
				setLoadingPage(false);
				ContractsDataRepository.getContractSummaries(customerResult.id).then((contractsResult: Contracts.Contract[]) => {
					setContracts(contractsResult);
					setLoadingContracts(false);
				});
				
				InvoicesDataRepository.getOpenInvoices("ASC").then((openInvoicesResult: Invoices.Invoice[]) => {
					setOpenInvoices(openInvoicesResult);
				});
				
				const brokerIds = customerResult.party
					.filter(x => x.entityType === "intermediary")
					.map((contract) => {
						const intermediary = contract as Contracts.Intermediary;
						return intermediary.porIdBroker;
					});
				
				if (brokerIds.length > 0) {
					OrganizationDataRepository.getBrokers(brokerIds).then((brokers: Organizations.Organisation[]) => {
						if (!brokers || brokers.length === 0)
							return;
						
						//todo handle multiple brokers
						const broker = brokers[0];
						setBrokerContactDetails({
							heading: "My advisor",
							name: broker.surname,
							address: formatter.address({
								city: broker.city,
								street: broker.street,
								postalCode: broker.postalCode,
								houseNumber: broker.houseNumber,
								houseNumberAddition: broker.houseNumberAddition
							}),
							contactNumber: formatter.contactNumber(broker.telephoneNumber),
							emailAddress: broker.email ?? broker.emailWork
						});
					});
				}
				
				setBannerProps({
					variant: "card",
					headerText: "Also insuring your car?",
					imageUrl: "/car-insurance.jpeg",
					cardMediaSlot: (
						<SbLinkButton to={"www.risk.nl"} windowOpenFeatures={{newTab: true, opener: false}}
							ButtonProps={{variant: "contained", color: "primary"}}>
							<SbTypography variant={"buttonLarge"}>Calculate your premium quickly</SbTypography>
						</SbLinkButton>
					),
					onAdvertClick() {
						window.open("www.risk.nl", "_blank");
					}
				});
			});
		}
		
		async function loadOverstappenData(): Promise<void> {
			if (!userSessionProvider.userSession?.user)
				throw new Error("No user session");
			
			const firstNames = userSessionProvider.userSession?.user.givenName;
			const lastNames = userSessionProvider.userSession?.user.familyName;
			PortalDataRepository.getCustomerRegistry(firstNames, lastNames).then((customerRegistry: Portal.CustomerRegisterDetailResult) => {
				if (!customerRegistry || customerRegistry.customerRegistryId === "")
					throw new Error("Customer not found");
				
				AuthorizationDataRepository.getUserTelephoneNumberByKrn(userSessionProvider.userSession?.user.srn ?? "").then((telephoneNumber: string) => {
					const customerDetailsCardProps: SbPolicyHolderCardProps = {
						variant: "summary",
						policyHolder: {
							id: customerRegistry.customerRegistryId,
							fullName: `${customerRegistry.firstName} ${customerRegistry.surname}`,
							birthDate: customerRegistry.birthDate ?? "",
							houseNumber: customerRegistry.houseNumber?.toString(),
							houseNumberAddition: customerRegistry.houseNumberAddition ?? undefined,
							postalCode: customerRegistry.postalCode,
							street: customerRegistry.street,
							city: customerRegistry.city,
							email: customerRegistry.email,
							contactNumber: formatter.contactNumber(telephoneNumber),
						}
					};
					
					setPolicyHolderCardProps(customerDetailsCardProps);
				});
				
				return PortalDataRepository.getDashboardOrders(customerRegistry.customerRegistryId);
			}).then((customerOrders: Portal.Order[]) => {
				const ordersArray: Portal.Order[] = [];
				const ordersLength = customerOrders.length;
				const krns: string[] = [];
				for (let i = 0; i < ordersLength; i++) {
					const order = customerOrders[i];
					if (order.productCategory !== "HEALTH_CARE")
						continue;
					
					ordersArray.push(order);
					if (order.package && order.package.customProperties?.productKrn && !krns.includes(order.package.customProperties?.productKrn))
						krns.push(order.package.customProperties?.productKrn);
				}
				
				setOrders(ordersArray);
				setLoadingPage(false);
				setLoadingContracts(false);
				PortalDataRepository.getOverstappenProducts().then((products: Portal.Product[]) => {
					setAvailableProducts(products);
				});
			});
		}
		
		if (TenantHelper.isSurebaseTenant()) {
			loadSurebaseData().then(() => {
				return;
			});
		} else if (TenantHelper.isOverstappenTenant()) {
			loadOverstappenData().then(() => {
				const randomNum = Math.floor(Math.random() * 5) + 1;
				let imageUrl = "";
				let advertLink = "";
				switch (randomNum) {
					case 1:
						imageUrl = "./products/autoverzekering.png";
						advertLink = "https://www.overstappen.nl/autoverzekering/?ref=mijnoverstappen";
						break;
					case 2:
						imageUrl = "./products/energie.png";
						advertLink = "https://www.overstappen.nl/energie/?ref=mijnoverstappen";
						break;
					case 3:
						imageUrl = "./products/tv.png";
						advertLink = "https://www.overstappen.nl/internet/?ref=mijnoverstappen";
						break;
					case 4:
						imageUrl = "./products/woonverzekering.png";
						advertLink = "https://www.overstappen.nl/woonverzekering/?ref=mijnoverstappen";
						break;
					case 5:
					default:
						imageUrl = "./products/zonnepanelen.png";
						advertLink = "https://www.overstappen.nl/zonnepanelen/?ref=mijnoverstappen";
						break;
					
				}
				
				setBannerProps({
					variant: "image",
					imageUrl: imageUrl,
					onAdvertClick() {
						window.open(advertLink, "_blank");
					}
				});
				return;
			});
		} else {
			throw new Error("Unrecognized tenant");
		}
		
	}, []);
	
	if (loadingPage) {
		return <Box sx={{...fullSize, display: "flex"}}>
			<CircularProgress style={{margin: "auto"}}/>
		</Box>;
	}
	
	const getGreeting = () => {
		let firstPart = "Good evening";
		const currentHours = new Date().getHours();
		if (currentHours > 6 && currentHours < 12)
			firstPart = "Good morning";
		else if (currentHours >= 12 && currentHours < 18)
			firstPart = "Good afternoon";
		
		if (TenantHelper.isOverstappenTenant() && policyHolderCardProps?.policyHolder) {
			return `${translate(firstPart)} ${policyHolderCardProps.policyHolder.fullName},`;
		}
		
		return `${translate(firstPart)} ${userSessionProvider.userSession?.user.name},`;
	};
	
	//Click events
	const handlePayInvoicesClick = (invoiceIds: string[]) => {
		//todo update routing when contract page is created
		console.log("pay invoices", invoiceIds);
	};
	
	const handleAdvisorClose = () => {
		setShowMobileBrokerDetails(false);
	};
	
	const getMobileActions = () => {
		return [
			{
				actionIcon: <User01Icon color={"primary"} width={24} height={24}/>,
				actionTitle: "Personal",
				actionClicked() {
					navigate("customer");
				}
			},
			{
				actionIcon: <MessageTextSquare02Icon color={"primary"} width={24} height={24}/>,
				actionTitle: "Messages",
				actionClicked() {
					navigate("messages");
				}
			},
			{
				actionIcon: <Loading02Icon color={"primary"} width={24} height={24}/>,
				actionTitle: "Claims",
				actionClicked() {
					navigate("claims");
				}
			},
			{
				actionIcon: <User01Icon color={"primary"} width={24} height={24}/>,
				actionTitle: "Advisor",
				actionClicked() {
					setShowMobileBrokerDetails(!showMobileBrokerDetails);
				}
			}
		];
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{...fullSize}}>
			<Stack gap={2}>
				<SbTypographyRaw variant={"cardButtonTitle2"}>{getGreeting()}</SbTypographyRaw>
			</Stack>
			
			{(TenantHelper.isSurebaseTenant() && inMobileView && brokerContactDetails) && (
				<div className={`card-container ${showMobileBrokerDetails ? "visible" : ""}`}>
					<ContactCard {...brokerContactDetails}
						actionSlot={(
							<SbIconButton IconButtonProps={{
								style: {marginLeft: "auto", padding: "0px"},
								onClick: handleAdvisorClose
							}}>
								<XCloseIcon height={24} width={24}/>
							</SbIconButton>
						)}/>
				</div>
			)}
			
			{(inMobileView && TenantHelper.isSurebaseTenant()) && (
				<Stack gap={2} style={{...fullWidth}}>
					<SbActionsCard containerStyle={{backgroundColor: "#FAFAFA", border: "none", padding: "0px"}}
						actionsContainerStyles={{padding: "0px"}}
						actionCardStyles={{backgroundColor: "#FFFFFF", borderRadius: "8px"}}
						actions={getMobileActions()}/>
				</Stack>
			)}
			
			{(inMobileView && TenantHelper.isOverstappenTenant() && policyHolderCardProps) && (
				<Stack gap={2} style={{
					...fullWidth,
					justifyContent: "center",
					flex: "1"
				}}>
					<SbPolicyHolderCard {...policyHolderCardProps} />
				</Stack>
			)}
			
			<SbTypography variant={"cardButtonTitle1"}>My products</SbTypography>
			<Stack style={{...fullWidth}} gap={4} direction={inMobileView ? "column" : "row"}>
				<Stack gap={2} style={{...fullWidth}}>
					<Stack gap={2} style={{...fullWidth}}>
						<Stack gap={2} direction={inMobileView ? "column" : "row"}
							style={{
								...fullWidth,
								alignItems: "flex-start",
								alignSelf: "stretch",
								flex: "2"
							}}>
							
							<Stack gap={2} style={{...fullWidth, flex: "1 1 0"}}>
								{loadingContracts && (
									<Box sx={{...fullSize, display: "flex"}}>
										<CircularProgress style={{margin: "auto"}}/>
									</Box>
								)}
								{(!loadingContracts) && (
									<ContractContent loadingContracts={loadingContracts} contracts={contracts}
										orders={orders}/>
								)}
							
							</Stack>
							
							{(!inMobileView && TenantHelper.isOverstappenTenant() && policyHolderCardProps) && (
								<Stack gap={2} style={{
									...fullWidth,
									justifyContent: "center",
									flex: "1"
								}}>
									<SbPolicyHolderCard {...policyHolderCardProps} />
								</Stack>
							)}
							
							{TenantHelper.isSurebaseTenant() && (
								<Stack gap={2} style={{
									...fullWidth,
									justifyContent: "center",
									alignItems: "flex-start",
									flex: "1"
								}}>
									<OutstandingInvoicesCard invoices={openInvoices}
										onPayClick={handlePayInvoicesClick}
										invoicesLink={"customer-portal/invoices"}/>
									
									{(TenantHelper.isSurebaseTenant() && !inMobileView && brokerContactDetails && shouldMoveContactCard) && (
										<Stack style={{width: "100%"}}>
											<ContactCard {...brokerContactDetails}/>
										</Stack>
									)}
								</Stack>
							)}
						</Stack>
					</Stack>
					
					{(!inMobileView && TenantHelper.isSurebaseTenant()) && (
						<SurebaseQuickLinksContent/>
					)}
					
					{(TenantHelper.isOverstappenTenant()) && (
						<AvailableProductsCard headerText={"Other products?"}
							bodyText={"Interested in other products you can save on? Take a look at the possibilities below"}
							availableProducts={availableProducts} collapsedAmount={8}/>
					)}
				
				</Stack>
				<Stack gap={3}>
					{bannerProps && (
						<ProductBanner {...bannerProps}/>
					)}
					{(TenantHelper.isSurebaseTenant() && !inMobileView && brokerContactDetails && !shouldMoveContactCard) && (
						<ContactCard {...brokerContactDetails}/>
					)}
				</Stack>
			</Stack>
		</Stack>
	</TranslationProvider>;
}, [permissions.customerRead]);


function SurebaseQuickLinksContent() {
	return <Stack direction={"row"} gap={2} alignItems={"flex-start"} style={{width: "100%"}}>
		<Stack flex="1" style={{height: "100%"}}>
			<DashBoardQuickLinksCard
				heading={"Personal"}
				headingIcon={<User01Icon color={"primary"} width={24} height={24}/>}
				bodyText={"Here you can see and edit your address and contact information"}
				buttonText={"Customize data"}
				linkTo={"customer-portal/customer"}
			/>
		</Stack>
		<Stack flex="1" style={{height: "100%"}}>
			<DashBoardQuickLinksCard
				heading={"Messages"}
				headingIcon={<MessageTextSquare02Icon color={"primary"} width={24} height={24}/>}
				bodyText={"Click here for an overview of all your current messages"}
				buttonText={"View messages"}
				linkTo={"customer-portal/messages"}
			/>
		</Stack>
		<Stack flex="1" style={{height: "100%"}}>
			<DashBoardQuickLinksCard
				heading={"Claims"}
				headingIcon={<Loading02Icon color={"primary"} width={24} height={24}/>}
				bodyText={"Here are your reported claims. You can also report a new damage here"}
				buttonText={"Report and manage claims"}
				linkTo={"customer-portal/claims"}
			/>
		</Stack>
	</Stack>;
}

interface ContractContentProps {
	loadingContracts: boolean;
	contracts?: Contracts.Contract[];
	orders?: Portal.Order[];
}

function ContractContent(props: ContractContentProps) {
	const hasAnyProducts = (props.contracts && props.contracts.length > 0) || (props.orders && props.orders.length > 0);
	const navigate = useNavigate();
	let notFoundProps: NotFoundCardProps;
	if (TenantHelper.isOverstappenTenant()) {
		notFoundProps = {
			heading: "No health insurance found",
			subText: "When you purchase health insurance it will be displayed here."
		};
	} else {
		notFoundProps = {
			heading: "No products found",
			subText: "When you purchase a product it will be displayed here."
		};
	}
	
	if (!hasAnyProducts) {
		return <>
			<NotFoundCard {...notFoundProps}/>
			<SbLinkButton to={"customer-portal/contracts"} ButtonProps={{
				color: "primary",
				style: {marginRight: "auto"},
				disableRipple: true
			}}>
				<SbTypography variant={"buttonSmall"}>Go to products page</SbTypography>
				<ChevronRightIcon/>
			</SbLinkButton>
		</>;
	}
	
	function getSbContractCardProps(contract: Contracts.Contract): SbContractCardProps {
		return {
			variant: "summary",
			contract: contract,
			onClick() {
				if (contract.statusTypeExplanation !== "Lopend")
					navigate("contracts?contract=" + contract.id + "&showAll=1");
				else
					navigate("contracts?contract=" + contract.id);
			}
		};
	}
	
	function getSbOrderCardProps(order: Portal.Order): SbOrderCardProps {
		return {
			variant: "summary",
			order: order,
			onDownloadDocument(document: Portal.Document) {
				return;
			},
			onClick() {
				navigate("contracts?contract=" + order.id);
			}
		};
	}
	
	return <Stack gap={1} style={{
		...fullWidth,
		justifyContent: "center",
		alignItems: "flex-start",
		flex: "2"
	}}>
		<Stack gap={2} style={{...fullWidth, flex: "1 1 0"}}>
			{props.loadingContracts && (
				<Box sx={{...fullSize, display: "flex"}}>
					<CircularProgress style={{margin: "auto"}}/>
				</Box>
			)}
			{(!props.loadingContracts && props.contracts) && props.contracts.map((contract, index) => (
				<SbContractCard key={index} {...getSbContractCardProps(contract)}/>
			))}
			{(!props.loadingContracts && props.orders) && props.orders.map((order, index) => (
				<SbOrderCard key={index} {...getSbOrderCardProps(order)}/>
			))}
		</Stack>
		<SbLinkButton to={"customer-portal/contracts"} ButtonProps={{
			color: "primary",
			style: {marginRight: "auto"},
			disableRipple: true
		}}>
			<SbTypography variant={"buttonSmall"}>Go to products page</SbTypography>
			<ChevronRightIcon/>
		</SbLinkButton>
	</Stack>;
}
