import {gql} from "@apollo/client";

export const GET_SUPPLIERS = gql`
	query GetSuppliers($where:ProductSupplierFilterInput!){
    productSuppliers(where:$where) {
        nodes {
            entityType
            refKey
            krn
            name
            legalName
            externalIds {
                providerKrn
                externalSupplierId
            }
            porCompany
            state
            nameLogoUrl
            addresses {
                id
                addressType
                city
                country
                houseNumber
                houseNumberAddition
                houseLetter
                postalCode
                street
            }
            informationContacts {
                id
                type
                replyNumber
                phoneNumber
                emailAddress
            }
            advantages{
                description
                productCategory
                clientName
            }
            disadvantages{
                description
                productCategory
                clientName
            }
            features{
                name
                value
                productCategory
                clientName
            }
            taskDivisions{
                id
                entityType
                creationDateTime
                lastModifiedBy
                lastModifiedDateTime
                productCategory
                clientName
                name
                value
                version
            }
            sustainabilityScore{
                source
                score
                clientName
            }
            sellingPoints{
                id
                entityType
                creationDateTime
                lastModifiedBy
                lastModifiedDateTime
                orderType
                clientName
                productCategory
                description
                value
                sellingPointType
                version
            }
            howItWorks {
                id
                entityType
                creationDateTime
                lastModifiedBy
                lastModifiedDateTime
                productCategory
                typeName
                value
                clientName
                version
            }
            about
            customAttributes {
                description
                value
            }
            id
            version
            creationDateTime
            lastModifiedDateTime
            lastModifiedBy
        }
    }
}`;

export const GET_PRODUCTS = gql`
query GetSuppliers($where:ProductFilterInput!){
    products(where:$where) {
        nodes {
            productSupplierKrn
            entityType
            refKey
            krn
            externalId
            dataProviderKrn
            name
            description
            productSupplierId
            state
            productType
            productTypeKrn
            orderType
            productCategory
            affiliateUrl
            insuranceCardUrl
            termsAndConditionsUrl
            clientName
            supplierGenericProduct
            correspondingMoneyViewExternalId
            coverages {
                category
                eventDescription
                definition
                coverageDays
            }
            customAttributes{
                description
                value
            }
            eventInformation{
                id
                category
                name
                definition
                duration
            }
            id
            version
            creationDateTime
            lastModifiedDateTime
            lastModifiedBy
        }
    }
}`;
