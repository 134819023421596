import {Environment} from "../global/Environment";

export class CookieBotHelper {
	static AddCookieBotWithStyles = (): void => {
		if (!Environment.cookieBotId)
			return;
		
		this.AddStyle();
		const script = document.createElement("script");
		script.id = "Cookiebot";
		script.src = "https://consent.cookiebot.com/uc.js";
		script.setAttribute("data-cbid", Environment.cookieBotId);
		script.setAttribute("data-blockingmode", "auto");
		script.type = "text/javascript";
		document.head.appendChild(script);
	};
	
	private static AddStyle = (): void => {
		const cookieBotStyle = document.createElement("style");
		cookieBotStyle.textContent = `
			#CybotCookiebotDialog { padding-block: 0 !important; }
			#CybotCookiebotDialog, #CybotCookiebotDialog * {
				font: inherit !important;
				font-size: 14px !important;
				color: #3b445e !important;
			}
			#CybotCookiebotDialogBodyLevelButtonCustomize,
			#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
				border: none !important;
			}
			#CybotCookiebotDialog a:not([id]),
			#CybotCookiebotDialogBodyEdgeMoreDetailsLink,
			#CybotCookiebotDialogBodyLevelButtonCustomize,
			#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
				text-decoration: underline !important;
			}
			@media(max-width:599px) {
				#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
					text-decoration: none !important;
				}
			}
			@media(max-width:1279px) {
				#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
					margin: 16px 24px 0 0 !important;
				}
			}
			@media(max-width:599px) {
				#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
					justify-content: center !important;
					margin: 16px 0 0 0 !important;
				}
			}
			#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a:after {
				content: unset !important;
			}
			.CybotCookiebotDialogContentWrapper {
				max-width: 1140px !important;
				justify-content: center !important;
			}
			.CybotCookiebotDialogBodyBottomWrapper {
				margin: 0 !important;
				padding-inline: 16px;
			}
			@media(min-width:1280px) {
				.CybotCookiebotDialogBodyBottomWrapper {
					margin-bottom: 24px !important;
					padding-inline: 24px !important;
				}
			}
			.CybotCookiebotScrollContainer {
				padding: 0 !important;
				min-height: unset !important;
			}
			#CybotCookiebotDialogTabContent {
				margin: 0 !important;
			}
			#CybotCookiebotDialogBody {
				column-gap: 24px !important;
			}
			#CybotCookiebotDialogBodyContent {
				padding: 24px 24px 12px !important;
				max-height: unset !important;
			}
			@media(min-width:600px) {
				#CybotCookiebotDialogFooter {
					padding: 24px !important;
				}
			}
			#CybotCookiebotDialogPoweredByText,
			#CybotCookiebotDialogBodyContentTitle,
			#CybotCookiebotDialogHeader,
			#CybotCookiebotDialog .CybotCookiebotFader,
			#CybotCookiebotDialogBodyButtonDecline,
			#CybotCookiebotDialogBodyLevelButtonCustomize,
			.CybotCookiebotDialogArrow {
				display: none !important;
			}
			#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
			#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
			#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
				border-radius: 8px !important;
				margin: 0 !important;
				color: #fff !important;
				font-weight: 600 !important;
			}
		`;
		
		document.head.appendChild(cookieBotStyle);
	};
}
