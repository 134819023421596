import {graphQlServices} from "../GraphQlServices";
import {Companies} from "./Companies";
import {GET_PRINCIPAL_INSURER_LIST} from "./CompaniesQueries";

export class CompaniesDataRepository {
	static async getPrincipalInsurers(ids: string[]): Promise<Companies.Company[]> {
		if (ids.length === 0)
			return [];
		
		const response = await graphQlServices.companyServices.query<Companies.Queries>(
			{
				query: GET_PRINCIPAL_INSURER_LIST,
				variables: {
					ids: ids
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.companyList ?? [];
	}
}
