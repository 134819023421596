import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import {
	Contracts,
	SbButton,
	SbDateTimePicker,
	SbDivider, SbProcessButton,
	SbTextField,
	SbTypography,
	SbTypographyRaw,
	User01Icon,
	XCloseIcon
} from "@surebase/shared-component-library";
import {LocalDate} from "@js-joda/core";
import React, {useState} from "react";
import {Customers} from "../../services/customers/graphql/Customers";
import {TasksDataRepository} from "../../services/tasks/TasksDataRepository";
import {Tasks} from "../../services/tasks/Tasks";
import PolicyDetailsInput = Tasks.PolicyDetailsInput;

export interface CustomerReportMoveDialogProps {
	readonly open: boolean;
	readonly onClose: () => void;
	readonly showAlert: (value: (((prevState: boolean) => boolean) | boolean)) => void;
	readonly customer?: Customers.Customer;
	readonly contracts?: Contracts.Contract[];
}

export function CustomerReportMoveDialog(props: CustomerReportMoveDialogProps) {
	const [postalCode, setPostalCode] = useState<string>("");
	const [postalCodeValid, setPostalCodeValid] = useState<boolean>(true);
	const [houseNumber, setHouseNumber] = useState<string>("");
	const [houseNumberValid, setHouseNumberValid] = useState<boolean>(true);
	const [street, setStreet] = useState<string>("");
	const [streetValid, setStreetValid] = useState<boolean>(true);
	const [city, setCity] = useState<string>("");
	const [cityValid, setCityValid] = useState<boolean>(true);
	const [movingDate, setMovingDate] = useState<string>(LocalDate.now().toString());
	const [remarks, setRemarks] = useState<string>("");
	
	const open = props.open;
	const onClose = props.onClose;
	const showAlert = props.showAlert;
	
	const customer = props.customer;
	const contracts = props.contracts;
	
	function validateForm() {
		let isPostalCodeValid: boolean;
		let isHouseNumberValid: boolean;
		let isStreetValid: boolean;
		let isCityValid: boolean;
		
		if (postalCode === undefined || postalCode == null || postalCode === "") {
			setPostalCodeValid(false);
			isPostalCodeValid = false;
		} else {
			setPostalCodeValid(true);
			isPostalCodeValid = true;
		}
		
		if (houseNumber === undefined || houseNumber == null || houseNumber === "") {
			setHouseNumberValid(false);
			isHouseNumberValid = false;
		} else {
			setHouseNumberValid(true);
			isHouseNumberValid = true;
		}
		
		if (street === undefined || street == null || street === "") {
			setStreetValid(false);
			isStreetValid = false;
		} else {
			setStreetValid(true);
			isStreetValid = true;
		}
		
		if (city === undefined || city == null || city === "") {
			setCityValid(false);
			isCityValid = false;
		} else {
			setCityValid(true);
			isCityValid = true;
		}
		
		return (isPostalCodeValid && isHouseNumberValid && isStreetValid && isCityValid);
	}
	
	return <Dialog
		open={open}
		onClose={onClose}
		fullWidth={true}
		maxWidth={"md"}
	>
		<DialogTitle style={{paddingBottom: "0px"}}>
			<SbTypography variant={"cardButtonTitle1"}>
				Report a move
			</SbTypography>
		</DialogTitle>
		
		<IconButton
			aria-label="close"
			onClick={onClose}
			sx={(theme) => ({
				position: "absolute",
				right: 8,
				top: 8,
				color: theme.palette.grey[500],
			})}
		>
			<XCloseIcon/>
		</IconButton>
		
		<DialogContent>
			<Stack gap={2}>
				<Stack gap={1}>
					<User01Icon color={"primary"}/>
					<Stack>
						<SbTypographyRaw variant={"cardButtonHeader4"}>
							{customer?.callName} {customer?.surname}
						</SbTypographyRaw>
						<SbTypographyRaw variant={"cardButtonContent2"} color={"#6C737F"}>
							{customer?.street} {customer?.houseNumber}
						</SbTypographyRaw>
						<SbTypographyRaw variant={"cardButtonContent2"} color={"#6C737F"}>
							{customer?.postalCode} {customer?.city}
						</SbTypographyRaw>
					</Stack>
				</Stack>
				
				<SbDivider/>
				
				<Stack gap={2}>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<SbTypography variant={"detailsContent2"}>
							Postal code *
						</SbTypography>
						
						<SbTextField
							variant={"outlined"}
							value={postalCode}
							style={{
								width: "50%"
							}}
							FieldProps={{size: "small"}}
							onChange={(value) => setPostalCode(value)}
							onBlur={(event) => setPostalCodeValid(event.target.value !== "")}
							error={postalCodeValid ? undefined : "error"}
						/>
					</Stack>
					
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<SbTypography variant={"detailsContent2"}>
							House number *
						</SbTypography>
						
						<SbTextField
							variant={"outlined"}
							value={houseNumber}
							style={{
								width: "50%"
							}}
							FieldProps={{size: "small"}}
							onChange={(value) => setHouseNumber(value)}
							onBlur={(event) => setHouseNumberValid(event.target.value !== "")}
							error={houseNumberValid ? undefined : "error"}
						/>
					</Stack>
					
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<SbTypography variant={"detailsContent2"}>
							Street *
						</SbTypography>
						
						<SbTextField
							variant={"outlined"}
							value={street}
							style={{
								width: "50%"
							}}
							FieldProps={{size: "small"}}
							onChange={(value) => setStreet(value)}
							onBlur={(event) => setStreetValid(event.target.value !== "")}
							error={streetValid ? undefined : "error"}
						/>
					</Stack>
					
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<SbTypography variant={"detailsContent2"}>
							City *
						</SbTypography>
						
						<SbTextField
							variant={"outlined"}
							value={city}
							style={{
								width: "50%"
							}}
							FieldProps={{size: "small"}}
							onChange={(value) => setCity(value)}
							onBlur={(event) => setCityValid(event.target.value !== "")}
							error={cityValid ? undefined : "error"}
						/>
					</Stack>
				</Stack>
				
				<SbDivider/>
				
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<SbTypography variant={"detailsContent2"}>
						Moving date *
					</SbTypography>
					
					<SbDateTimePicker
						type={LocalDate}
						format={"DD-MM-YYYY"}
						value={LocalDate.parse(movingDate)}
						DatePickerProps={{
							sx: {width: "50%"},
							slotProps: {textField: {size: "small"}},
						}}
						onChange={(value) => setMovingDate(value.toString())}
					/>
				</Stack>
				
				<SbDivider/>
				
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
				>
					<SbTypography variant={"detailsContent2"}>
						Remarks
					</SbTypography>
					
					<SbTextField
						variant={"outlined"}
						value={remarks}
						style={{
							width: "50%"
						}}
						FieldProps={{size: "small", multiline: true, minRows: 4, maxRows: 4}}
						onChange={(value) => setRemarks(value)}
					/>
				</Stack>
			</Stack>
		</DialogContent>
		
		<DialogActions style={{padding: "20px 24px", paddingTop: "0px"}}>
			<SbProcessButton
				LoadingButtonProps={{
					startIcon: <></>,
					variant: "contained",
					size: "large",
					onClick: () => {
						if (!validateForm())
							return;
						
						const allIntermediaries = contracts !== undefined
							? contracts.map(c => c.party.find(p => p.entityType === "intermediary") as Customers.Intermediary)
							: [];
						
						const uniqueIntermediaries = allIntermediaries.filter((value, index, self) =>
							index === self.findIndex((t) => (
								t.porIdBroker === value.porIdBroker
							))
						);
						
						uniqueIntermediaries.forEach((intermediary) => {
							const contractsPerBroker = contracts?.filter(c => (c.party.find(p => p.entityType === "intermediary") as Customers.Intermediary).porIdBroker === intermediary.porIdBroker);
							
							const policyDetails = contractsPerBroker !== undefined
								? contractsPerBroker.map(c => {
									return {
										contractNumber: c.contractNumber,
										contractModificationIdSourceSystem: c.contractModificationIdSourceSystem,
										refKey: c.id
									} as PolicyDetailsInput;
								})
								: [];
							
							TasksDataRepository.upsertTaskById(
								"CustomerPortal - Verhuizing",
								`Postcode: ${postalCode}, Huisnummer: ${houseNumber}, Straat: ${street}, City: ${city}, Verhuisdatum: ${movingDate}, Opmerkingen: ${remarks}`,
								"A",
								movingDate,
								[
									{
										customer: {
											refKey: customer?.id,
											relationNumber: customer?.relationNumber
										}
									},
									{
										intermediary: {
											refKey: intermediary.refKey,
											porIdBroker: intermediary.porIdBroker
										}
									}
								],
								policyDetails
							).then();
						});
						
						onClose();
						showAlert(true);
					}
				}}
				defaultContent={
					<SbTypography variant={"buttonMedium"}>
						Send
					</SbTypography>
				}
			/>
			<SbButton
				ButtonProps={{
					size: "large",
					color: "inherit",
					variant: "outlined",
					onClick: onClose
				}}
			>
				<SbTypography variant={"buttonMedium"}>
					Cancel
				</SbTypography>
			</SbButton>
		</DialogActions>
	</Dialog>;
}
