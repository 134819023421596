import React, {ReactNode} from "react";
import {
	fullSize,
	SbTypography
} from "@surebase/shared-component-library";
import {
	Card,
	Stack,
	useTheme
} from "@mui/material";

export interface NotFoundCardProps {
	readonly heading: string;
	readonly subText?: string;
	readonly link?: ReactNode;
}

export function NotFoundCard(props: NotFoundCardProps) {
	const theme = useTheme();
	return <Card variant="outlined" style={{
		padding: "16px",
		alignItems: "center",
		gap: "16px",
		alignSelf: "stretch",
		minHeight: "94px"
	}}>
		<Stack direction={"row"} alignItems={"center"} flexGrow={1} gap={2} style={{...fullSize}}>
			<Stack style={{flexGrow: 1, minWidth: 0}}>
				<SbTypography variant={"cardButton1"} color={theme.palette.text.primary}>
					{props.heading}
				</SbTypography>
				
				{props.subText && (
					<Stack direction={"row"}>
						<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
							{props.subText}
						</SbTypography>
					</Stack>
				)}
				
				{props.link && (
					props.link
				)}
			</Stack>
		</Stack>
	</Card>;
}
