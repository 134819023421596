export namespace Tasks {
	export interface Queries {
		readonly tasks: TasksConnection | null;
		readonly taskById: SurebaseTask | null;
		readonly taskByRelationNumber: SurebaseTask | null;
		readonly taskByPolicyNumber: SurebaseTask | null;
		readonly taskByClaimNumber: SurebaseTask | null;
		readonly taskByDossierNumber: SurebaseTask | null;
		readonly subTaskByParentTaskId: SurebaseTask | null;
		readonly preSignedUrlForUpload: UploadInfo;
		readonly preSignedUrlForDownload: ReturnArchive[];
		readonly filesByIdListAsync: TaskArchive[];
		readonly confirmUpload: boolean;
		readonly upsertTaskById: UpsertTaskByIdPayload;
		readonly changeTaskStatus: ChangeTaskStatusPayload;
	}
	
	export interface Error {
		readonly message: string;
	}
	
	export interface IGraphQlErrorBase {
		readonly type: string;
		readonly message: string;
	}
	
	export interface Party {
		readonly refKey: string | null;
		readonly entityType: string;
	}
	
	export interface Assignee {
		readonly entityType: string;
		readonly krn: string;
		readonly name: string;
		readonly refKey: string | null;
	}
	
	export interface ChangeTaskStatusPayload {
		readonly boolean: boolean | null;
	}
	
	export interface Claim {
		readonly entityType: string;
		readonly refKey: string | null;
		readonly claimNumberCarrier: string;
	}
	
	export interface Customer {
		readonly entityType: string;
		readonly relationNumber: string;
		readonly refKey: string | null;
	}
	
	export interface Dossier {
		readonly entityType: string;
		readonly refKey: string | null;
		readonly fileNumber: string;
	}
	
	export interface Intermediary {
		readonly entityType: string;
		readonly porIdBroker: string;
		readonly refKey: string | null;
	}
	
	export interface NotAuthenticatedError {
		readonly message: string;
		readonly reason: string;
		readonly type: string;
	}
	
	export interface NotAuthorizedError {
		readonly message: string;
		readonly user: string;
		readonly action: string;
		readonly resource: string;
		readonly reason: string;
		readonly type: string;
	}
	
	// Information about pagination in a connection.
	export interface PageInfo {
		// Indicates whether more edges exist following the set defined by the clients arguments.
		readonly hasNextPage: boolean;
		// Indicates whether more edges exist prior the set defined by the clients arguments.
		readonly hasPreviousPage: boolean;
		// When paginating backwards, the cursor to continue.
		readonly startCursor: string | null;
		// When paginating forwards, the cursor to continue.
		readonly endCursor: string | null;
	}
	
	export interface PolicyDetails {
		readonly entityType: string;
		readonly contractNumber: string;
		readonly contractModificationIdSourceSystem: string;
		readonly refKey: string | null;
	}
	
	export interface ReturnArchive {
		readonly id: string | null;
		readonly frontEndId: string | null;
		readonly extension: string;
		readonly name: string;
		readonly size: string;
		readonly preSignedUrl: string | null;
		readonly contentType: string | null;
		readonly expires: string;
	}
	
	export interface SurebaseTask {
		readonly entityType: string;
		readonly id: string;
		readonly version: number | null;
		readonly surebaseTaskName: string;
		readonly surebaseTaskDescription: string;
		readonly surebaseTaskType: SclTt;
		readonly statusType: SclSt;
		readonly priority: AdnPri;
		readonly effectiveDate: string;
		readonly taskDestination: AssigneeType;
		readonly taskOrigin: AssigneeType;
		readonly fileIdList: string[] | null;
		readonly party: Party[];
		readonly claim: Claim[] | null;
		readonly dossier: Dossier[] | null;
		readonly policyDetails: PolicyDetails[] | null;
		readonly parentTaskId: string | null;
		readonly creationDateTime: string | null;
		readonly lastModifiedDateTime: string | null;
		readonly lastModifiedBy: string | null;
		readonly externalSourceName: string;
		readonly externalSourceId: string | null;
		readonly deleteAfter: string | null;
		readonly krn: string;
	}
	
	export interface TaskArchive {
		readonly id: string;
		readonly extension: string;
		readonly krn: string;
		readonly name: string;
		readonly size: string;
		readonly s3Name: string;
		readonly uploadDate: string | null;
	}
	
	// A connection to a list of items.
	export interface TasksConnection {
		// Information to aid in pagination.
		readonly pageInfo: PageInfo;
		// A list of edges.
		readonly edges: TasksEdge[] | null;
		// A flattened list of the nodes.
		readonly nodes: SurebaseTask[] | null;
	}
	
	// An edge in a connection.
	export interface TasksEdge {
		// A cursor for use in pagination.
		readonly cursor: string;
		// The item at the end of the edge.
		readonly node: SurebaseTask;
	}
	
	export interface UploadInfo {
		readonly files: ReturnArchive[];
	}
	
	export interface UpsertTaskByIdPayload {
		readonly uuid: string | null;
		readonly errors: UpsertTaskByIdError[] | null;
	}
	
	export type UpsertTaskByIdError = NotAuthorizedError | NotAuthenticatedError;
	
	export interface AdnPriOperationFilterInput {
		readonly eq?: AdnPri | null;
		readonly neq?: AdnPri | null;
		readonly in?: AdnPri[] | null;
		readonly nin?: AdnPri[] | null;
	}
	
	export interface AssigneeInput {
		readonly krn?: string;
		readonly name?: string;
	}
	
	export interface AssigneeTypeOperationFilterInput {
		readonly eq?: AssigneeType | null;
		readonly neq?: AssigneeType | null;
		readonly in?: AssigneeType[] | null;
		readonly nin?: AssigneeType[] | null;
	}
	
	export interface ChangeTaskStatusInput {
		readonly statusType?: SclSt;
		readonly taskId?: string;
	}
	
	export interface ClaimFilterInput {
		readonly and?: ClaimFilterInput[] | null;
		readonly or?: ClaimFilterInput[] | null;
		readonly entityType?: StringOperationFilterInput | null;
		readonly refKey?: StringOperationFilterInput | null;
		readonly claimNumberCarrier?: StringOperationFilterInput | null;
	}
	
	export interface ClaimInput {
		readonly refKey?: string | null;
		readonly claimNumberCarrier?: string;
	}
	
	export interface CustomerInput {
		readonly relationNumber?: string;
		readonly refKey?: string | null;
	}
	
	export interface DateOperationFilterInput {
		readonly eq?: string | null;
		readonly neq?: string | null;
		readonly in?: (string | null)[] | null;
		readonly nin?: (string | null)[] | null;
		readonly gt?: string | null;
		readonly ngt?: string | null;
		readonly gte?: string | null;
		readonly ngte?: string | null;
		readonly lt?: string | null;
		readonly nlt?: string | null;
		readonly lte?: string | null;
		readonly nlte?: string | null;
	}
	
	export interface DateTimeOperationFilterInput {
		readonly eq?: string | null;
		readonly neq?: string | null;
		readonly in?: (string | null)[] | null;
		readonly nin?: (string | null)[] | null;
		readonly gt?: string | null;
		readonly ngt?: string | null;
		readonly gte?: string | null;
		readonly ngte?: string | null;
		readonly lt?: string | null;
		readonly nlt?: string | null;
		readonly lte?: string | null;
		readonly nlte?: string | null;
	}
	
	export interface DossierFilterInput {
		readonly and?: DossierFilterInput[] | null;
		readonly or?: DossierFilterInput[] | null;
		readonly entityType?: StringOperationFilterInput | null;
		readonly refKey?: StringOperationFilterInput | null;
		readonly fileNumber?: StringOperationFilterInput | null;
	}
	
	export interface DossierInput {
		readonly refKey?: string | null;
		readonly fileNumber?: string;
	}
	
	export interface IntOperationFilterInput {
		readonly eq?: number | null;
		readonly neq?: number | null;
		readonly in?: (number | null)[] | null;
		readonly nin?: (number | null)[] | null;
		readonly gt?: number | null;
		readonly ngt?: number | null;
		readonly gte?: number | null;
		readonly ngte?: number | null;
		readonly lt?: number | null;
		readonly nlt?: number | null;
		readonly lte?: number | null;
		readonly nlte?: number | null;
	}
	
	export interface IntermediaryInput {
		readonly porIdBroker?: string;
		readonly refKey?: string | null;
	}
	
	export interface ListFilterInputTypeOfClaimFilterInput {
		readonly all?: ClaimFilterInput | null;
		readonly none?: ClaimFilterInput | null;
		readonly some?: ClaimFilterInput | null;
		readonly any?: boolean | null;
	}
	
	export interface ListFilterInputTypeOfDossierFilterInput {
		readonly all?: DossierFilterInput | null;
		readonly none?: DossierFilterInput | null;
		readonly some?: DossierFilterInput | null;
		readonly any?: boolean | null;
	}
	
	export interface ListFilterInputTypeOfPartyFilterInput {
		readonly all?: PartyFilterInput | null;
		readonly none?: PartyFilterInput | null;
		readonly some?: PartyFilterInput | null;
		readonly any?: boolean | null;
	}
	
	export interface ListFilterInputTypeOfPolicyDetailsFilterInput {
		readonly all?: PolicyDetailsFilterInput | null;
		readonly none?: PolicyDetailsFilterInput | null;
		readonly some?: PolicyDetailsFilterInput | null;
		readonly any?: boolean | null;
	}
	
	export interface ListUuidOperationFilterInput {
		readonly all?: UuidOperationFilterInput | null;
		readonly none?: UuidOperationFilterInput | null;
		readonly some?: UuidOperationFilterInput | null;
		readonly any?: boolean | null;
	}
	
	export interface PartyFilterInput {
		readonly and?: PartyFilterInput[] | null;
		readonly or?: PartyFilterInput[] | null;
		readonly refKey?: StringOperationFilterInput | null;
		readonly entityType?: StringOperationFilterInput | null;
	}
	
	export interface PartyInput {
		readonly customer?: CustomerInput | null;
		readonly intermediary?: IntermediaryInput | null;
		readonly assignee?: AssigneeInput | null;
	}
	
	export interface PolicyDetailsFilterInput {
		readonly and?: PolicyDetailsFilterInput[] | null;
		readonly or?: PolicyDetailsFilterInput[] | null;
		readonly entityType?: StringOperationFilterInput | null;
		readonly contractNumber?: StringOperationFilterInput | null;
		readonly contractModificationIdSourceSystem?: StringOperationFilterInput | null;
		readonly refKey?: StringOperationFilterInput | null;
	}
	
	export interface PolicyDetailsInput {
		readonly refKey?: string | null;
		readonly contractNumber?: string;
		readonly contractModificationIdSourceSystem?: string;
	}
	
	export interface SclStOperationFilterInput {
		readonly eq?: SclSt | null;
		readonly neq?: SclSt | null;
		readonly in?: SclSt[] | null;
		readonly nin?: SclSt[] | null;
	}
	
	export interface SclTtOperationFilterInput {
		readonly eq?: SclTt | null;
		readonly neq?: SclTt | null;
		readonly in?: SclTt[] | null;
		readonly nin?: SclTt[] | null;
	}
	
	export interface StringOperationFilterInput {
		readonly and?: StringOperationFilterInput[] | null;
		readonly or?: StringOperationFilterInput[] | null;
		readonly eq?: string | null;
		readonly neq?: string | null;
		readonly contains?: string | null;
		readonly ncontains?: string | null;
		readonly in?: (string | null)[] | null;
		readonly nin?: (string | null)[] | null;
		readonly startsWith?: string | null;
		readonly nstartsWith?: string | null;
		readonly endsWith?: string | null;
		readonly nendsWith?: string | null;
	}
	
	export interface SurebaseTaskFilterInput {
		readonly and?: SurebaseTaskFilterInput[] | null;
		readonly or?: SurebaseTaskFilterInput[] | null;
		readonly entityType?: StringOperationFilterInput | null;
		readonly id?: UuidOperationFilterInput | null;
		readonly version?: IntOperationFilterInput | null;
		readonly surebaseTaskName?: StringOperationFilterInput | null;
		readonly surebaseTaskDescription?: StringOperationFilterInput | null;
		readonly surebaseTaskType?: SclTtOperationFilterInput | null;
		readonly statusType?: SclStOperationFilterInput | null;
		readonly priority?: AdnPriOperationFilterInput | null;
		readonly effectiveDate?: DateOperationFilterInput | null;
		readonly taskDestination?: AssigneeTypeOperationFilterInput | null;
		readonly taskOrigin?: AssigneeTypeOperationFilterInput | null;
		readonly fileIdList?: ListUuidOperationFilterInput | null;
		readonly party?: ListFilterInputTypeOfPartyFilterInput | null;
		readonly claim?: ListFilterInputTypeOfClaimFilterInput | null;
		readonly dossier?: ListFilterInputTypeOfDossierFilterInput | null;
		readonly policyDetails?: ListFilterInputTypeOfPolicyDetailsFilterInput | null;
		readonly parentTaskId?: UuidOperationFilterInput | null;
		readonly creationDateTime?: DateTimeOperationFilterInput | null;
		readonly lastModifiedDateTime?: DateTimeOperationFilterInput | null;
		readonly lastModifiedBy?: StringOperationFilterInput | null;
		readonly externalSourceName?: StringOperationFilterInput | null;
		readonly externalSourceId?: StringOperationFilterInput | null;
		readonly deleteAfter?: DateTimeOperationFilterInput | null;
		readonly krn?: StringOperationFilterInput | null;
	}
	
	export interface SurebaseTaskInput {
		readonly id?: string | null;
		readonly version?: number | null;
		readonly surebaseTaskName?: string;
		readonly surebaseTaskDescription?: string;
		readonly surebaseTaskType?: SclTt;
		readonly priority?: AdnPri;
		readonly effectiveDate?: string;
		readonly taskDestination?: AssigneeType;
		readonly taskOrigin?: AssigneeType;
		readonly externalSourceName?: string;
		readonly fileIdList?: string[] | null;
		readonly party?: PartyInput[];
		readonly claim?: ClaimInput[] | null;
		readonly dossier?: DossierInput[] | null;
		readonly policyDetails?: PolicyDetailsInput[] | null;
	}
	
	export interface SurebaseTaskSortInput {
		readonly entityType?: SortEnumType | null;
		readonly id?: SortEnumType | null;
		readonly version?: SortEnumType | null;
		readonly surebaseTaskName?: SortEnumType | null;
		readonly surebaseTaskDescription?: SortEnumType | null;
		readonly surebaseTaskType?: SortEnumType | null;
		readonly statusType?: SortEnumType | null;
		readonly priority?: SortEnumType | null;
		readonly effectiveDate?: SortEnumType | null;
		readonly taskDestination?: SortEnumType | null;
		readonly taskOrigin?: SortEnumType | null;
		readonly parentTaskId?: SortEnumType | null;
		readonly creationDateTime?: SortEnumType | null;
		readonly lastModifiedDateTime?: SortEnumType | null;
		readonly lastModifiedBy?: SortEnumType | null;
		readonly externalSourceName?: SortEnumType | null;
		readonly externalSourceId?: SortEnumType | null;
		readonly deleteAfter?: SortEnumType | null;
		readonly krn?: SortEnumType | null;
	}
	
	export interface TaskArchiveFileUploadInput {
		readonly taskOrigin?: AssigneeType;
		readonly extension?: string;
		readonly name?: string;
		readonly size?: string;
		readonly frontEndId?: string;
	}
	
	export interface UpsertTaskByIdInput {
		readonly surebaseTask?: SurebaseTaskInput;
	}
	
	export interface UuidOperationFilterInput {
		readonly eq?: string | null;
		readonly neq?: string | null;
		readonly in?: (string | null)[] | null;
		readonly nin?: (string | null)[] | null;
		readonly gt?: string | null;
		readonly ngt?: string | null;
		readonly gte?: string | null;
		readonly ngte?: string | null;
		readonly lt?: string | null;
		readonly nlt?: string | null;
		readonly lte?: string | null;
		readonly nlte?: string | null;
	}
	
	export const adnPri = [
		"V00001",
		"V00002",
		"V00003",
		"V00004",
		"V00005",
	] as const;
	export type AdnPri = typeof adnPri[number];
	
	export const applyPolicy = [
		"BEFORE_RESOLVER",
		"AFTER_RESOLVER",
		"VALIDATION",
	] as const;
	export type ApplyPolicy = typeof applyPolicy[number];
	
	export const assigneeType = [
		"BACKOFFICE",
		"CUSTOMER",
		"INTERMEDIARY",
	] as const;
	export type AssigneeType = typeof assigneeType[number];
	
	export const sclSt = [
		"T",
		"D",
	] as const;
	export type SclSt = typeof sclSt[number];
	
	export const sclTt = [
		"R",
		"A",
		"BVO",
		"DA",
		"D",
		"FV",
		"N",
		"O",
		"SP",
		"OMV",
		"RY",
		"S",
		"WP",
		"WRI",
		"T",
		"ER",
		"AB",
		"C",
		"B",
		"AO",
		"FO",
		"KM",
		"NO",
		"RO",
		"RAO",
		"WO",
	] as const;
	export type SclTt = typeof sclTt[number];
	
	export const sortEnumType = [
		"ASC",
		"DESC",
	] as const;
	export type SortEnumType = typeof sortEnumType[number];
}
