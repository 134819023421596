import {graphQlServices} from "../../GraphQlServices";
import {GET_CLAIMS} from "./ClaimsQueries";
import {Claims} from "./Claims";
import {CustomersDataRepository} from "../../customers/graphql/CustomersDataRepository";

export class ClaimsDataRepository {
	static async getClaims(): Promise<Claims.Claim[]> {
		const ids = await CustomersDataRepository.getCustomerIds();
		const response = await graphQlServices.claimsServices.query<Claims.Queries>(
			{
				query: GET_CLAIMS,
				variables: {
					first: 50,
					where: {
						party: {
							some: {
								refKey: {in: ids}
							}
						}
					},
					order: [
						{dateLossReportedToCarrier: "DESC"},
					]
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return [];
		
		return response.data.claims?.nodes ?? [];
	}
}
