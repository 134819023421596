import {graphQlServices} from "../../GraphQlServices";
import {GET_CUSTOMER, GET_CUSTOMER_SUMMARY} from "./CustomersQueries";
import {Customers} from "./Customers";

export class CustomersDataRepository {
	public static _customerIds: string[] = [];
	private static _customer: Customers.Customer | null;
	
	static async getCustomerIds(): Promise<string[]> {
		if (this._customerIds.length !== 0)
			return this._customerIds;
		
		return this.getCustomerSummary().then((customer: Customers.Customer | null) => {
			return this._customerIds;
		});
	}
	
	static async getCustomer(): Promise<Customers.Customer | null> {
		const response = await graphQlServices.customerServices.query<Customers.Queries>(
			{
				query: GET_CUSTOMER,
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		if (!response.data?.customers?.nodes || response.data?.customers?.nodes.length === 0)
			return null;
		
		return response.data?.customers?.nodes[0];
	}
	
	static async getCustomerSummary(): Promise<Customers.Customer | null> {
		if (this._customer)
			return this._customer;
		
		const response = await graphQlServices.customerServices.query<Customers.Queries>(
			{
				query: GET_CUSTOMER_SUMMARY,
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		if (!response.data.customers?.nodes)
			return null;
		
		const length = response.data?.customers?.nodes.length;
		this._customerIds = [];
		for (let i = 0; i < length; i++) {
			const customer = response.data.customers.nodes[i];
			if (this._customerIds.includes(customer.id))
				continue;
			
			this._customerIds.push(customer.id);
		}
		
		if (!response.data?.customers?.nodes || response.data?.customers?.nodes.length === 0)
			return null;
		
		this._customer = response.data?.customers?.nodes[0];
		return this._customer;
	}
}
