import React, {CSSProperties, ReactNode} from "react";
import {
	ArrowNarrowRightIcon,
	Building03Icon,
	SbTypography,
	SbTypographyRaw,
	TranslationProvider
} from "@surebase/shared-component-library";
import {
	Card,
	CardContent,
	Stack,
	useTheme
} from "@mui/material";
import {Settings} from "../../global/Settings";

export interface ContactCardProps {
	readonly heading: string;
	readonly name: string;
	readonly nameLink?: string;
	readonly address: string;
	readonly contactNumber?: string | null;
	readonly emailAddress?: string | null;
	readonly openingHours?: string;
	readonly actionSlot?: ReactNode;
	readonly style?: CSSProperties;
}

export function ContactCard(props: ContactCardProps) {
	const theme = useTheme();
	return <TranslationProvider name={Settings.languageModule}>
		<Card variant={"outlined"}>
			<CardContent>
				<Stack gap={1} style={{...props.style}}>
					<Stack direction={"row"} gap={1} style={{alignItems: "center"}}>
						<Building03Icon width={24} height={24} color={"primary"}/>
						<SbTypography variant={"labelSmall"}
							style={{color: theme.palette.primary.main}}>{props.heading}</SbTypography>
						
						{props.actionSlot && (
							props.actionSlot
						)}
					</Stack>
					<Stack gap={1}>
						{props.nameLink && (
							<Stack direction={"row"} gap={1} alignItems={"center"} style={{cursor: "pointer"}}
								onClick={() => {
									window.open(props.nameLink, "_blank");
								}}>
								<SbTypographyRaw variant={"cardsTitle2"}>{props.name}</SbTypographyRaw>
								<ArrowNarrowRightIcon width={20} height={20}/>
							</Stack>
						)}
						{!props.nameLink && (
							<SbTypographyRaw variant={"cardsTitle2"}>{props.name}</SbTypographyRaw>
						)}
						<Stack>
							{props.address && (
								<SbTypographyRaw variant={"infoFieldValue"}>{props.address}</SbTypographyRaw>
							)}
							{props.contactNumber && (
								<SbTypographyRaw variant={"infoFieldValue"}>{props.contactNumber}</SbTypographyRaw>
							)}
							{props.emailAddress && (
								<SbTypographyRaw variant={"infoFieldValue"}>{props.emailAddress}</SbTypographyRaw>
							)}
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	</TranslationProvider>;
}
