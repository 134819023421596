import React, {CSSProperties} from "react";
import {Card, CardActionArea, Divider, Stack, useTheme} from "@mui/material";
import {
	formatter,
	parseLocalDateTime,
	SbEllipse,
	SbTypographyRaw,
	useMobileView,
} from "@surebase/shared-component-library";
import {MessageBox} from "../../../services/message-box/graphql/MessageBox";
import {grey} from "@mui/material/colors";

export interface MessageItemProps {
	readonly message: MessageBox.Message;
	readonly onClick: (message: MessageBox.Message) => void;
	readonly markAsRead: (message: MessageBox.Message) => Promise<void>;
}

export function MessageListItem(props: MessageItemProps) {
	const inMobileView = useMobileView();
	const theme = useTheme();
	const primaryText = theme.palette.text.primary;
	const secondaryText = theme.palette.text.secondary;
	const messageCategory = formatter.enum(props.message.messageCategory);
	
	const headerTypographyStyles = (compressed: boolean): CSSProperties => {
		if (compressed) {
			return {};
		}
		
		return {
			textOverflow: "ellipsis",
			maxWidth: "300px",
			display: "inline-block",
			whiteSpace: "nowrap",
			overflow: "hidden"
		};
	};
	
	return <Card variant={"outlined"}>
		<CardActionArea onClick={async () => {
			await props.markAsRead(props.message);
			props.onClick(props.message);
		}}>
			<Stack direction={"row"} gap={1} alignItems={"center"} style={{width: "100%", padding: "16px"}}>
				{!props.message.readDateTime && (
					<SbEllipse style={{margin: undefined, marginTop: "auto", marginBottom: "auto"}}/>
				)}
				<Stack direction={"row"} style={{width: "100%"}}>
					<Stack style={{width: "50%"}}>
						<SbTypographyRaw variant={"cardButtonHeader3"} color={primaryText}
							style={headerTypographyStyles(inMobileView)}>
							{props.message.header}
						</SbTypographyRaw>
					</Stack>
					<Stack style={{width: "50%"}}>
						<SbTypographyRaw variant={"body2"} color={secondaryText} style={{marginLeft: "auto"}}>
							{formatter.dateTime(parseLocalDateTime(props.message.creationDateTime), {
								year: "numeric",
								month: inMobileView ? "2-digit" : "short",
								day: "2-digit",
								hour: "2-digit",
								minute: "2-digit",
								second: undefined,
								hour12: false
							})}
						</SbTypographyRaw>
					</Stack>
				</Stack>
			</Stack>
			<Divider color={grey[300]}/>
			<Stack style={{width: "100%", padding: "16px"}}>
				<SbTypographyRaw variant={"cardButtonContent2"} color={primaryText}>
					{props.message.senderPlatform} {messageCategory} &#8208;
				</SbTypographyRaw>
				<SbTypographyRaw variant={"cardButtonContent2"} color={secondaryText}>
					{props.message.subject}
				</SbTypographyRaw>
			</Stack>
		</CardActionArea>
	</Card>;
}
