import React, {useEffect, useRef, useState} from "react";
import {Card, CardContent, CardHeader, Divider, Stack, useTheme} from "@mui/material";
import {
	ChevronDownIcon,
	ChevronUpIcon,
	SbIconButton,
	SbLinkButton,
	SbTypography,
	useMobileView
} from "@surebase/shared-component-library";
import {grey} from "@mui/material/colors";
import {IconLookup} from "../../helpers/IconLookup";
import {Portal} from "../../services/portal/graphql/Portal";

export interface AvailableProductsCardProps {
	readonly availableProducts: Portal.Product[];
	readonly headerText?: string;
	readonly bodyText?: string;
	readonly collapsedAmount?: number;
}

export function AvailableProductsCard(props: AvailableProductsCardProps) {
	const theme = useTheme();
	const inMobileView = useMobileView();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [expanded, setExpanded] = useState<boolean>(false);
	
	useEffect(() => {
		if (expanded && buttonRef.current) {
			buttonRef.current.scrollIntoView({behavior: "smooth", block: "center"});
		}
	}, [expanded]);
	
	const getVisibleProducts = () => {
		if (!props.collapsedAmount || expanded)
			return props.availableProducts;
		
		const amountToShow = (props.collapsedAmount > props.availableProducts.length) ? props.availableProducts.length : props.collapsedAmount;
		return props.availableProducts.slice(0, amountToShow);
	};
	
	const onToggleAccordion = () => {
		setExpanded(!expanded);
	};
	
	const getProductDisplayName = (product: Portal.Product, inMobileView: boolean) => {
		if (inMobileView && product.shortName)
			return product.shortName;
		
		return product.name;
	};
	
	return <Stack>
		<Card variant={"outlined"} style={{borderRadius: "8px 8px 0px 0px"}}>
			{props.headerText && (
				<>
					<CardHeader style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}}
						avatar={(
							<Stack gap={2} alignItems={"center"} direction={"row"}>
								<SbTypography variant={"cardButtonTitle1"}>{props.headerText}</SbTypography>
							</Stack>
						)}>
					</CardHeader>
					<Divider color={grey[300]}/>
				</>
			)}
			<CardContent style={{padding: "16px", gap: "16px"}}>
				<Stack gap={2}>
					{props.bodyText && (
						<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
							{props.bodyText}
						</SbTypography>
					)}
					
					<Stack gap={2} alignItems="flex-start" justifyContent="center"
						sx={{
							display: "grid",
							gridTemplateColumns: inMobileView ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
							width: "100%"
						}}>
						{getVisibleProducts().map((product, index) => (
							<Card key={index} variant="outlined"
								sx={{
									width: "100%",
									boxSizing: "border-box",
								}}>
								<SbLinkButton to={product.link} windowOpenFeatures={{newTab: true, opener: false}}
									ButtonProps={{
										style: {height: "100%", width: "100%", padding: "32px"}
									}}>
									<Stack alignItems="center" gap={1} style={{maxWidth: "100%"}}>
										{IconLookup.getIconFromName(product.icon, 24, "#2B2F3B")}
										<SbTypography variant="cardButtonHeader4" style={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											maxWidth: "100%"
										}} color={theme.palette.text.primary}>
											{getProductDisplayName(product, inMobileView)}
										</SbTypography>
									</Stack>
								</SbLinkButton>
							</Card>
						))}
					</Stack>
				</Stack>
			</CardContent>
		</Card>
		
		<Card variant={"outlined"}
			style={{borderTop: "none", padding: "4px 0px 4px 0px", borderRadius: "0px 0px 8px 8px"}}
			onClick={() => onToggleAccordion()}>
			<Stack>
				<SbIconButton children={
					<Stack direction={"row"} gap={1}>
						<SbTypography variant={"productButton2Bold"} color={theme.palette.text.primary}>
							{expanded ? "Show less" : "Show more"}
						</SbTypography>
						{!expanded && (
							<ChevronDownIcon color={theme.palette.text.primary}
								style={{width: "18px", height: "18px", flexShrink: 0}}/>
						)}
						{expanded && (
							<ChevronUpIcon color={theme.palette.text.primary}
								style={{width: "18px", height: "18px", flexShrink: 0}}/>
						)}
					</Stack>
				} IconButtonProps={{
					ref: buttonRef,
					style: {marginLeft: "auto"},
					size: "small",
					disableRipple: true,
					onClick: () => onToggleAccordion()
				}}/>
			</Stack>
		</Card>
	</Stack>;
}
