import {gql} from "@apollo/client";

export const GET_CONTRACTS = gql`
	query GetContracts($first: Int, $where: ContractFilterInput, $order: [ContractSortInput!]) {
		contracts(first: $first, where: $where, order: $order) {
			nodes {
				id
				contractNumber
				contractModificationIdSourceSystem
				coverage {
					entityType
					... on AccidentCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						insuredFamilyComposition
					}
			
					... on AgentCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on AwningCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on BmNoclaimRelapseCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on BodyJewelryCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on BreakdownAssistanceCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on BuildingCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on CancellationCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on CompoundJourneyCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on CyberCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on ElectronicsCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on GlassCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						insuredFamilyComposition
					}
			
					... on HailStormCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on HomeCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on HullCaravanCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
					}
			
					... on HullExpansionCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on HullTrailerCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on HullVehicleCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
					}
			
					... on HullVesselCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on InventoryGoodsCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on LegalCounselConsumersHousingCoverage {
						productDescription
						totalInstallmentAmount
						insuredFamilyComposition
					}
			
					... on LegalCounselCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on LegalCounselIncomeCoverage {
						productDescription
						totalInstallmentAmount
						insuredFamilyComposition
					}
			
					... on LegalCounselTaxAssetsCoverage {
						productDescription
						totalInstallmentAmount
						insuredFamilyComposition
					}
			
					... on LegalCounselTrafficCoverage {
						productDescription
						totalInstallmentAmount
						insuredFamilyComposition
					}
			
					... on LiabilityCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on LuggageCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on MaterialDamagePassengerCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on MedicalExpensesCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on MoneyCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on OutdoorsCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on PersonalAccidentsCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
					}
			
					... on RentalCoverage {
						productDescription
						totalInstallmentAmount
					}
			
					... on ReplacementVehicleCoverage {
						productDescription
						totalInstallmentAmount
						coverageAreaCode
					}
			
					... on SportsEquipmentCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on TenantCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on ThirdPartyLiabilityCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						insuredAmountMaterialDamage
						insuredAmountPersonalInjury
						coverageAreaCode
					}
			
					... on TransportationCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
			
					... on TravelCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on ValuablesCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
						insuredFamilyComposition
					}
			
					... on VehicleAccessoriesCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						coverageAreaCode
					}
			
					... on VsvCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
						insuredFamilyComposition
					}
			
					... on WintersportsCoverage {
						productDescription
						totalInstallmentAmount
						sumInsured
					}
				}
				effectiveDate
				expiryDate
				krn
				mgaBusinessLineType
				mgaBusinessLineTypeDescription
				mgaSubBusinessLineType
				mgaSubBusinessLineTypeDescription
				party {
					entityType
					
					... on Intermediary {
						porIdBroker
					}
					
					... on PolicyHolder {
						relationNumber
					}
					
					... on PrincipalInsurer {
						refKey
					}
				}
				paymentTermInMonths
				statusType
				statusTypeExplanation
				terminationDate
				totalInstallmentAmount
				underwriting {
					entityType
					... on UnderwritingQuestions {
					numberOfClaimFreeYearsInsurer
					}
				}
				afdObject {
					entityType
					
					... on BuildingObject {
						houseNumber
						houseNumberAddition
						street
						postalCode,
						city
					}
					
					... on CaravanObject {
						caravanType
						licensePlate
					}
					
					... on DinghyObject {
						make
						vesselName
						vesselType
					}
					
					... on MopedBicycleObject {
						make
						model
						licensePlate
					}
					
					... on MotorVehicleObject {
						make
						model
						licensePlate
					}
					
					... on TrailerObject {
						make
						model
						licensePlate
					}
					
					... on TravelObject {
						startDateJourney
						endDateJourney
						maximumTravelTimeInDays
					}
					
					... on ValuablesObject {
						entityType
					}
					
					... on VesselEngineObject {
						vesselEngineType
						make
						model
					}
					
					... on VesselObject {
						berthName
						make
						model
					}
				}
			}
		}
	}`;

export const GET_CONTRACT_SUMMARIES = gql`
	query GetContracts($first: Int, $where: ContractFilterInput, $order: [ContractSortInput!]) {
		contracts(first: $first, where: $where, order: $order) {
			totalCount
			nodes {
				id
				expiryDate
				initialRenewalDate
				krn
				productDescription
				productName
				statusType
				statusTypeExplanation
				terminationDate
				mgaBusinessLineType
				mgaBusinessLineTypeDescription
				mgaSubBusinessLineType
				mgaSubBusinessLineTypeDescription
				afdObject {
					entityType
					
					... on BuildingObject {
						houseNumber
						houseNumberAddition
						street
						postalCode,
						city
					}
					
					... on CaravanObject {
						caravanType
						licensePlate
					}
					
					... on DinghyObject {
						make
						vesselName
						vesselType
					}
					
					... on MopedBicycleObject {
						make
						model
						licensePlate
					}
					
					... on MotorVehicleObject {
						make
						model
						licensePlate
					}
					
					... on TrailerObject {
						make
						model
						licensePlate
					}
					
					... on TravelObject {
						startDateJourney
						endDateJourney
						maximumTravelTimeInDays
					}
					
					... on ValuablesObject {
						entityType
					}
					
					... on VesselEngineObject {
						vesselEngineType
						make
						model
					}
					
					... on VesselObject {
						berthName
						make
						model
					}
				}
			}
		}
	}`;

export const GET_CONTRACT_COVERAGE = gql`
	query GetContractById($id: UUID!) {
		contractById(id: $id) {
			id
			krn
			coverage {
				entityType
				... on AccidentCoverage {
					productDescription
				}
				
				... on AgentCoverage {
					productDescription
				}
				
				... on AwningCoverage {
					productDescription
				}
				
				... on BmNoclaimRelapseCoverage {
					productDescription
				}
				
				... on BodyJewelryCoverage {
					productDescription
				}
				
				... on BreakdownAssistanceCoverage {
					productDescription
				}
				
				... on BuildingCoverage {
					productDescription
				}
				
				... on CancellationCoverage {
					productDescription
				}
				
				... on CompoundJourneyCoverage {
					productDescription
				}
				
				... on CyberCoverage {
					productDescription
				}
				
				... on ElectronicsCoverage {
					productDescription
				}
				
				... on GlassCoverage {
					productDescription
				}
				
				... on HailStormCoverage {
					productDescription
				}
				
				... on HomeCoverage {
					productDescription
				}
				
				... on HullCaravanCoverage {
					productDescription
				}
				
				... on HullExpansionCoverage {
					productDescription
				}
				
				... on HullTrailerCoverage {
					productDescription
				}
				
				... on HullVehicleCoverage {
					productDescription
				}
				
				... on HullVesselCoverage {
					productDescription
				}
				
				... on InventoryGoodsCoverage {
					productDescription
				}
				
				... on LegalCounselConsumersHousingCoverage {
					productDescription
				}
				
				... on LegalCounselCoverage {
					productDescription
				}
				
				... on LegalCounselIncomeCoverage {
					productDescription
				}
				
				... on LegalCounselTaxAssetsCoverage {
					productDescription
				}
				
				... on LegalCounselTrafficCoverage {
					productDescription
				}
				
				... on LiabilityCoverage {
					productDescription
				}
				
				... on LuggageCoverage {
					productDescription
				}
				
				... on MaterialDamagePassengerCoverage {
					productDescription
				}
				
				... on MedicalExpensesCoverage {
					productDescription
				}
				
				... on MoneyCoverage {
					productDescription
				}
				
				... on OutdoorsCoverage {
					productDescription
				}
				
				... on PersonalAccidentsCoverage {
					productDescription
				}
				
				... on RentalCoverage {
					productDescription
				}
				
				... on ReplacementVehicleCoverage {
					productDescription
				}
				
				... on SportsEquipmentCoverage {
					productDescription
				}
				
				... on TenantCoverage {
					productDescription
				}
				
				... on ThirdPartyLiabilityCoverage {
					productDescription
				}
				
				... on TransportationCoverage {
					productDescription
				}
				
				... on TravelCoverage {
					productDescription
				}
				
				... on ValuablesCoverage {
					productDescription
				}
				
				... on VehicleAccessoriesCoverage {
					productDescription
				}
				
				... on VsvCoverage {
					productDescription
				}
				
				... on WintersportsCoverage {
					productDescription
				}
			}
		}
	}`;

export const GET_CONTRACTS_COVERAGE = gql`
	query GetContractsCoverageTypeList {
		contractsCoverageTypeList {
			code
			description
		}
	}`;
