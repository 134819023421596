import {Tasks} from "./Tasks";
import {graphQlServices} from "../GraphQlServices";
import {upsertTaskById} from "./TaskMutations";
import SclTt = Tasks.SclTt;
import PartyInput = Tasks.PartyInput;
import PolicyDetailsInput = Tasks.PolicyDetailsInput;

export class TasksDataRepository {
	static async upsertTaskById(name: string, description: string, type: SclTt, date: string, party: PartyInput[], policyDetails: PolicyDetailsInput[]): Promise<void> {
		const response = await graphQlServices.tasksService.mutate<Tasks.Queries>({
			mutation: upsertTaskById,
			variables: {
				input: {
					surebaseTask: {
						surebaseTaskName: name,
						surebaseTaskDescription: description,
						surebaseTaskType: type,
						priority: "V00005",
						effectiveDate: date,
						taskDestination: "INTERMEDIARY",
						taskOrigin: "CUSTOMER",
						externalSourceName: "Surebase",
						party: party,
						policyDetails: policyDetails
					}
				}
			},
			fetchPolicy: "no-cache"
		});
		
		if (response.errors)
			throw response.errors;
	}
}
