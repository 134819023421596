import {graphQlServices} from "../../GraphQlServices";
import {Dossiers} from "./Dossiers";
import {DOSSIER_FILE, GET_CONTRACT_DOSSIERS, GET_DOSSIERS_BY_RELATION_ID} from "./DossierQueries";

export class DossierDataRepository {
	
	static async getCustomerDossiers(customerId: string): Promise<Dossiers.Dossier[] | null> {
		const response = await graphQlServices.dossierService.query<Dossiers.Queries>(
			{
				query: GET_DOSSIERS_BY_RELATION_ID,
				variables: {
					first: 20,
					where: {
						party: {
							some: {
								and: [
									{refKey: {eq: customerId}},
									{entityType: {eq: "policyHolder"}}
								]
							}
						}
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.dossiers?.nodes ?? [];
	}
	
	static async getCustomerDossiersV2(contractIds: string[]): Promise<Dossiers.Dossier[] | null> {
		const response = await graphQlServices.dossierService.query<Dossiers.Queries>(
			{
				query: GET_DOSSIERS_BY_RELATION_ID,
				variables: {
					first: 20,
					where: {
						policyDetails: {
							refKey: {in: contractIds}
						}
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.dossiers?.nodes ?? [];
	}
	
	
	static async getContractDocuments(contractId: string): Promise<Dossiers.Dossier[] | null> {
		// const whereClause: Dossiers.DossierFilterInput = {
		// 	and: [
		// 		{
		// 			policyDetails: {refKey: {eq: contractId}}
		// 		},
		// 		{
		// 			attachmentType: {
		// 				in: [
		// 					"V12",
		// 					"V15",
		// 					"V34"
		// 				]
		// 			}
		// 		}
		// 	]
		// };
		
		const response = await graphQlServices.dossierService.query<Dossiers.Queries>(
			{
				query: GET_CONTRACT_DOSSIERS,
				variables: {
					where: {policyDetails: {refKey: {eq: contractId}}},
					order: [{effectiveDate: "DESC"}]
				},
				fetchPolicy: "no-cache",
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.dossiers?.nodes ?? [];
	}
	
	
	static async getClaimDocuments(contractId: string): Promise<Dossiers.Dossier[] | null> {
		// const whereClause: Dossiers.DossierFilterInput = {
		// 	and: [
		// 		{
		// 			policyDetails: {refKey: {eq: contractId}}
		// 		},
		// 		{
		// 			attachmentType: {
		// 				in: [
		// 					"V12",
		// 					"V15",
		// 					"V34"
		// 				]
		// 			}
		// 		}
		// 	]
		// };
		
		const response = await graphQlServices.dossierService.query<Dossiers.Queries>(
			{
				query: GET_CONTRACT_DOSSIERS,
				variables: {
					where: {policyDetails: {refKey: {eq: contractId}}},
					order: [{effectiveDate: "DESC"}]
				},
				fetchPolicy: "no-cache",
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.dossiers?.nodes ?? [];
	}
	
	static async getDownloadUrlForDossier(dossierId: string): Promise<Dossiers.DownloadFile | null> {
		const response = await graphQlServices.dossierService.query<Dossiers.Queries>(
			{
				query: DOSSIER_FILE,
				variables: {
					id: dossierId,
				},
				fetchPolicy: "no-cache",
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.fileByDossierId;
	}
}
