import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PortalDataRepository} from "../../services/portal/graphql/PortalDataRepository";
import {Box, CircularProgress} from "@mui/material";

export const InvitePage = (() => {
	const {id} = useParams();
	const navigate = useNavigate();
	
	PortalDataRepository.acceptInvite(id ?? "").then((status) => {
		navigate("/dashboard");
	});
	
	return <Box sx={{display: "flex", width: "100%", height: "100%"}}>
		<CircularProgress style={{margin: "auto"}}/>
	</Box>;
	
});
