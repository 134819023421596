import {gql} from "@apollo/client";

export const GET_INVOICES = gql`
	query GetInvoices($first: Int, $where: InvoiceFilterInput, $order: [InvoiceSortInput!], $after: String) {
		invoices(first: $first, where: $where, order: $order, after: $after) {
			pageInfo {
				hasNextPage
				endCursor
			}
			nodes {
				entityType
				anvaDelete
				id
				version
				creationDateTime
				lastModifiedDateTime
				lastModifiedBy
				krn
				externalSourceName
				contractModificationIdSourceSystem
				invoiceNumber
				bookingDate
				invoiceAmount
				amountOutstandingInvoice
				collectionMethod
				bookingType
				collectionNumber
				documentRef {
					refKey
					fileNumber
				}
			}
		}
	}`;
