import React, {ReactNode} from "react";
import {
	AlertCircleIcon,
	AmbulanceCarBoldIcon,
	AnimalPrintBoldIcon,
	BicycleBoldIcon,
	CarBoldIcon,
	CurrencyEuroCircleBoldIcon,
	DatingRoseBoldIcon,
	FlashBoldIcon,
	HospitalFirstAidBoldIcon,
	PlaneTakeOffBoldIcon,
	RealEstateActionHouseShieldBoldIcon,
	RenewableEnergySolarPanelBoldIcon,
	SavingPiggyCoinsBoldIcon,
	ScooterBoldIcon,
	ScooterSportBoldIcon,
	SimCard2BoldIcon,
	WifiCheckBoldIcon
} from "@surebase/shared-component-library";

export class IconLookup {
	static getIconFromName = (name: string, size = 24, color: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | string = "primary"): ReactNode => {
		switch (name) {
			case "CarBoldIcon":
				return <CarBoldIcon height={size} width={size} color={color}/>;
			case "RealEstateActionHouseShieldBoldIcon":
				return <RealEstateActionHouseShieldBoldIcon height={size} width={size} color={color}/>;
			case "PlaneTakeOffBoldIcon":
				return <PlaneTakeOffBoldIcon height={size} width={size} color={color}/>;
			case "AmbulanceCarBoldIcon":
				return <AmbulanceCarBoldIcon height={size} width={size} color={color}/>;
			case "FlashBoldIcon":
				return <FlashBoldIcon height={size} width={size} color={color}/>;
			case "WifiCheckBoldIcon":
				return <WifiCheckBoldIcon height={size} width={size} color={color}/>;
			case "SavingPiggyCoinsBoldIcon":
				return <SavingPiggyCoinsBoldIcon height={size} width={size} color={color}/>;
			case "BicycleBoldIcon":
				return <BicycleBoldIcon height={size} width={size} color={color}/>;
			case "RenewableEnergySolarPanelBoldIcon":
				return <RenewableEnergySolarPanelBoldIcon height={size} width={size} color={color}/>;
			case "AnimalPrintBoldIcon":
				return <AnimalPrintBoldIcon height={size} width={size} color={color}/>;
			case "DatingRoseBoldIcon":
				return <DatingRoseBoldIcon height={size} width={size} color={color}/>;
			case "ScooterSportBoldIcon":
				return <ScooterSportBoldIcon height={size} width={size} color={color}/>;
			case "ScooterBoldIcon":
				return <ScooterBoldIcon height={size} width={size} color={color}/>;
			case "SimCardBoldIcon":
				return <SimCard2BoldIcon height={size} width={size} color={color}/>;
			case "CurrencyEuroCircleBoldIcon":
				return <CurrencyEuroCircleBoldIcon height={size} width={size} color={color}/>;
			case "HospitalFirstAidBoldIcon":
				return <HospitalFirstAidBoldIcon height={size} width={size} color={color}/>;
			default:
				return <AlertCircleIcon height={size} width={size} color={color}/>;
		}
	};
}
