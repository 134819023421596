import {GET_BROKERS_BY_ID} from "./OrganizationQueries";
import {graphQlServices} from "../GraphQlServices";
import {Organizations} from "./Organizations";

export class OrganizationDataRepository {
	static async getBrokers(porIds: string[]): Promise<Organizations.Organisation[]> {
		const response = await graphQlServices.organizationsService.query<Organizations.Queries>(
			{
				query: GET_BROKERS_BY_ID,
				variables: {
					porIds: porIds
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.organisationList ?? [];
	}
}
