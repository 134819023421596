import {gql} from "@apollo/client";

export const GET_BROKERS_BY_ID = gql`
	query GetOrganisationList($porIds: [String!]!) {
		organisationList(porIds: $porIds) {
			id
			entityType
			krn
			surname
			telephoneNumber
			postalCode
			street
			houseNumber
			houseNumberAddition
			houseNumberAddition
			city
		}
	} `;
