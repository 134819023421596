import React, {CSSProperties, ReactNode} from "react";
import {Card, CardHeader, CardMedia, Divider, Stack} from "@mui/material";
import {SbTypography, useMobileView} from "@surebase/shared-component-library";
import {grey} from "@mui/material/colors";

export interface ProductBannerProps {
	readonly variant: "image" | "card";
	readonly headerText?: string;
	readonly bodyText?: string;
	readonly imageUrl: string;
	readonly cardMediaSlot?: ReactNode;
	readonly style?: CSSProperties;
	
	onAdvertClick(): void;
}

export function ProductBanner(props: ProductBannerProps) {
	const inMobileView = useMobileView();
	return <Stack gap={3} style={{
		width: inMobileView ? "100%" : "327px",
		alignItems: "flex-start",
		cursor: "pointer",
		...props.style
	}}>
		
		{props.variant === "card" && (
			<CardContent imageUrl={props.imageUrl} onAdvertClick={props.onAdvertClick} headerText={props.headerText}
				bodyText={props.bodyText} cardMediaSlot={props.cardMediaSlot}/>
		)}
		{props.variant === "image" && (
			<ImageContent imageUrl={props.imageUrl} mobileView={inMobileView} onAdvertClick={props.onAdvertClick}/>
		)}
	</Stack>;
}

interface CardContentProps {
	readonly headerText?: string;
	readonly bodyText?: string;
	readonly imageUrl: string;
	readonly cardMediaSlot?: ReactNode;
	
	onAdvertClick(): void;
}

function CardContent(props: CardContentProps) {
	return <Card variant={"outlined"} style={{width: "100%", borderColor: grey[300]}}>
		{props.headerText && (
			<>
				<CardHeader style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}} title={
					<SbTypography variant={"cardButtonTitle1"}>
						{props.headerText}
					</SbTypography>}>
				</CardHeader>
				<Divider color={grey[300]}/>
			</>
		)}
		<CardMedia
			sx={{
				height: "457px",
				display: "flex",
				padding: "24px",
				flexDirection: "column",
				justifyContent: "flex-end",
				alignItems: "center",
				gap: "16px"
			}}
			onClick={() => props.onAdvertClick()}
			image={props.imageUrl}>
			{props.cardMediaSlot && (
				props.cardMediaSlot
			)}
		</CardMedia>
	</Card>;
}

interface ImageContentProps {
	readonly imageUrl: string;
	readonly mobileView: boolean;
	
	onAdvertClick(): void;
}

function ImageContent(props: ImageContentProps) {
	return <img src={props.imageUrl}
		alt={"product-banner"}
		style={{
			width: props.mobileView ? "100%" : "327px",
			height: "628px",
			objectFit: "contain",
			display: "block",
			overflow: "hidden",
		}}
		onClick={() => props.onAdvertClick()}
	/>;
}
