import {graphQlServices} from "../../GraphQlServices";
import {GET_CONTRACT_COVERAGE, GET_CONTRACT_SUMMARIES, GET_CONTRACTS, GET_CONTRACTS_COVERAGE} from "./ContractsQueries";
import {Contracts} from "./Contracts";
import {CustomersDataRepository} from "../../customers/graphql/CustomersDataRepository";

export class ContractsDataRepository {
	
	static async getContractsCoverageTypes(): Promise<Contracts.CoverageTypeItem[]> {
		const response = await graphQlServices.contractsServices.query<Contracts.Queries>(
			{
				query: GET_CONTRACTS_COVERAGE,
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return [];
		
		return response.data.contractsCoverageTypeList ?? [];
	}
	
	static async getContractSummaries(customerId: string): Promise<Contracts.Contract[]> {
		const response = await graphQlServices.contractsServices.query<Contracts.Queries>(
			{
				query: GET_CONTRACT_SUMMARIES,
				variables: {
					first: 3,
					where: {
						party: {
							some: {
								and: [
									{entityType: {eq: "policyHolder"}},
									{refKey: {eq: customerId}}
								]
							}
						}
					},
					order: [
						{statusType: "ASC"},
						{mgaSubBusinessLineType: "ASC"},
						{expiryDate: "DESC"}
					]
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return [];
		
		return response.data.contracts?.nodes ?? [];
	}
	
	static async getContracts(showAll: boolean): Promise<Contracts.Contract[]> {
		const ids = await CustomersDataRepository.getCustomerIds();
		const response = await graphQlServices.contractsServices.query<Contracts.Queries>(
			{
				query: GET_CONTRACTS,
				variables: {
					first: 100,
					where: ContractsDataRepository.getContractsFilter(ids, showAll),
					order: [
						{statusType: "ASC"},
						{mgaSubBusinessLineType: "ASC"},
						{expiryDate: "DESC"}
					],
					fetchPolicy: "no-cache"
				}
			}
		);
		
		if (response.error)
			return [];
		
		return response.data.contracts?.nodes ?? [];
	}
	
	static async getContractCoverage(contractId: string): Promise<Contracts.Coverage[] | null> {
		const response = await graphQlServices.contractsServices.query<Contracts.Queries>(
			{
				query: GET_CONTRACT_COVERAGE,
				variables: {
					id: contractId
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return null;
		
		return response.data.contractById?.coverage ?? [];
	}
	
	static getContractsFilter(ids: string[], showAll: boolean): Contracts.ContractFilterInput {
		const customerFilter: Contracts.ContractFilterInput = {
			party: {
				some: {
					refKey: {in: ids}
				}
			}
		};
		
		if (showAll)
			return customerFilter;
		
		return {
			and: [
				{statusType: {eq: "V1"}},
				customerFilter
			]
		};
	}
}
