import {sharedGraphQlServices} from "@surebase/shared-component-library";
import {Authorization} from "./Authorization";
import {getUserTelephoneNumber} from "./AuthQueries";

export class AuthorizationDataRepository {
	static async getUserTelephoneNumberByKrn(krn: string): Promise<string> {
		if (krn === "")
			return "";
		
		const response = await sharedGraphQlServices.authorizationClient.query<Authorization.Queries>({
			query: getUserTelephoneNumber,
			variables: {
				input: {
					krn: krn
				}
			},
			fetchPolicy: "no-cache"
		});
		
		
		if (response.error)
			throw response.errors;
		
		return response.data.findUser?.telephoneNumber ?? "";
	}
}
