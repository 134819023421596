import {graphQlServices} from "../../GraphQlServices";
import {Invoices} from "./Invoices";
import {GET_INVOICES} from "./InvoicesQueries";
import {ApolloQueryResult} from "@apollo/client";
import {CustomersDataRepository} from "../../customers/graphql/CustomersDataRepository";

export class InvoicesDataRepository {
	
	static async getOpenInvoices(sortOrder: "ASC" | "DESC" = "DESC"): Promise<Invoices.Invoice[]> {
		const ids = await CustomersDataRepository.getCustomerIds();
		const response = await graphQlServices.invoicesService.query<Invoices.Queries>(
			{
				query: GET_INVOICES,
				variables: {
					first: 3,
					where: this.getInvoicesDateFilter(ids),
					order: [
						{bookingDate: sortOrder},
					]
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.invoices?.nodes ?? [];
	}
	
	static async getInvoices(): Promise<Invoices.Invoice[]> {
		const ids = await CustomersDataRepository.getCustomerIds();
		let allInvoices: Invoices.Invoice[] = [];
		let hasNextPage = true;
		let afterCursor: string | null = null;
		while (hasNextPage) {
			const response: ApolloQueryResult<Invoices.Queries> = await graphQlServices.invoicesService.query<Invoices.Queries>(
				{
					query: GET_INVOICES,
					variables: {
						first: 50,
						after: afterCursor,
						where: this.getInvoicesDateFilter(ids),
						order: [
							{bookingDate: "DESC"},
						]
					},
					fetchPolicy: "no-cache"
				}
			);
			
			if (response.error)
				throw response.error;
			
			const invoices = response.data?.invoices?.nodes ?? [];
			allInvoices = allInvoices.concat(invoices);
			hasNextPage = response.data?.invoices?.pageInfo?.hasNextPage ?? false;
			afterCursor = response.data?.invoices?.pageInfo?.endCursor ?? null;
		}
		
		return allInvoices;
	}
	
	private static getInvoicesDateFilter(customerIds: string[]) {
		const pastYear = new Date();
		pastYear.setMonth(pastYear.getMonth() - 12);
		const pastTwoYears = new Date();
		pastTwoYears.setMonth(pastTwoYears.getMonth() - 24);
		
		return {
			and: [
				{
					amountOutstandingInvoice: {gt: 0.000001}
				},
				{
					party: {
						some: {
							and: [
								{refKey: {in: customerIds}},
								{entityType: {"eq": "customer"}}
							]
						}
					}
				},
				{
					or: [
						{
							and: [
								{
									paymentTermInMonths: {lte: 12}
								},
								{
									bookingDate: {gte: pastYear.toISOString()}
								}
							]
						},
						{
							and: [
								{
									paymentTermInMonths: {
										gt: 12
									}
								},
								{
									bookingDate: {gte: pastTwoYears.toISOString()}
								}
							]
						}
					]
				}
			]
		};
	}
}
