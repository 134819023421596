import {graphQlServices} from "../../GraphQlServices";
import {
	ACCEPT_INVITE,
	GET_AVAILABLE_PRODUCTS,
	GET_CUSTOMER_REGISTRY_DETAILS,
	GET_ORDERS,
	GET_OVERSTAPPEN_DETAILS, GET_OVERSTAPPEN_PRODUCTS, GET_TOP_DASHBOARD_ORDERS
} from "./PortalQueries";
import {Portal} from "./Portal";

export class PortalDataRepository {
	static async acceptInvite(id: string): Promise<boolean> {
		const response = await graphQlServices.portalServices.query<Portal.Queries>(
			{
				query: ACCEPT_INVITE,
				variables: {
					input: {
						invite: {
							id
						}
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return false;
		
		return response.data?.acceptInvite.inviteAcceptedResponse?.successful ?? false;
	}
	
	static async getAvailableProducts(): Promise<Portal.Product[]> {
		const response = await graphQlServices.portalServices.query<Portal.Queries>(
			{
				query: GET_AVAILABLE_PRODUCTS,
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return [];
		
		return response.data?.availableProducts ?? [];
	}
	
	
	static async getOverstappenDetails(): Promise<Portal.BrokerPayload | null> {
		const response = await graphQlServices.portalServices.query<Portal.Queries>(
			{
				query: GET_OVERSTAPPEN_DETAILS,
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return null;
		
		return response.data?.overstappenDetails;
	}
	
	static async getOverstappenProducts(): Promise<Portal.Product[]> {
		const response = await graphQlServices.portalServices.query<Portal.Queries>(
			{
				query: GET_OVERSTAPPEN_PRODUCTS,
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return [];
		
		return response.data?.overstappenProducts ?? [];
	}
	
	static async getCustomerRegistry(firstName: string, lastName: string): Promise<Portal.CustomerRegisterDetailResult> {
		const response = await graphQlServices.portalServices.query<Portal.Queries>(
			{
				query: GET_CUSTOMER_REGISTRY_DETAILS,
				variables: {
					request: {
						firstName: firstName,
						surname: lastName
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.customerRegisterDetails;
	}
	
	static async getDashboardOrders(customerRegistryId: string): Promise<Portal.Order[]> {
		const response = await graphQlServices.portalServices.query<Portal.Queries>(
			{
				query: GET_TOP_DASHBOARD_ORDERS,
				variables: {
					customerRegistryId: customerRegistryId,
					first: 3,
					where: {
						and: [
							{productCategory: {eq: "HEALTH_CARE"}},
							{orderStatus: {in: ["UnderReview", "Done"]}}
						]
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return [];
		
		return response.data?.orders?.nodes ?? [];
	}
	
	static async getOrders(customerRegistryId: string): Promise<Portal.Order[]> {
		const response = await graphQlServices.portalServices.query<Portal.Queries>(
			{
				query: GET_ORDERS,
				variables: {
					customerRegistryId: customerRegistryId,
					where: {
						and: [
							{productCategory: {eq: "HEALTH_CARE"}},
							{orderStatus: {in: ["UnderReview", "Done"]}}
						]
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return [];
		
		return response.data?.orders?.nodes ?? [];
	}
}
