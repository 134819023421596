import {ApolloClient} from "@apollo/client";
import {createApolloClient, sharedConfiguration} from "@surebase/shared-component-library";

class GraphQlServices {
	private _claimsServices: ApolloClient<any> | null = null;
	private _customerServices: ApolloClient<any> | null = null;
	private _contractsServices: ApolloClient<any> | null = null;
	private _companyServices: ApolloClient<any> | null = null;
	private _dossierService: ApolloClient<any> | null = null;
	private _invoicesService: ApolloClient<any> | null = null;
	private _organizationsService: ApolloClient<any> | null = null;
	private _portalServices: ApolloClient<any> | null = null;
	private _productsServices: ApolloClient<any> | null = null;
	private _tasksService: ApolloClient<any> | null = null;
	
	get claimsServices(): ApolloClient<any> {
		return this._claimsServices || (this._claimsServices = createApolloClient(
			`${sharedConfiguration.backendHost}/claims/graphql`
		));
	}
	
	get customerServices(): ApolloClient<any> {
		return this._customerServices || (this._customerServices = createApolloClient(
			`${sharedConfiguration.backendHost}/customers/graphql`
		));
	}
	
	get contractsServices(): ApolloClient<any> {
		return this._contractsServices || (this._contractsServices = createApolloClient(
			`${sharedConfiguration.backendHost}/contracts/graphql`
		));
	}
	
	get companyServices(): ApolloClient<any> {
		return this._companyServices || (this._companyServices = createApolloClient(
			`${sharedConfiguration.backendHost}/companies/graphql`
		));
	}
	
	get dossierService(): ApolloClient<any> {
		return this._dossierService || (this._dossierService = createApolloClient(
			`${sharedConfiguration.backendHost}/dossiers/graphql`
		));
	}
	
	get invoicesService(): ApolloClient<any> {
		return this._invoicesService || (this._invoicesService = createApolloClient(
			`${sharedConfiguration.backendHost}/invoices/graphql`
		));
	}
	
	get organizationsService(): ApolloClient<any> {
		return this._organizationsService || (this._organizationsService = createApolloClient(
			`${sharedConfiguration.backendHost}/organisations/graphql`
		));
	}
	
	get portalServices(): ApolloClient<any> {
		return this._portalServices || (this._portalServices = createApolloClient(
			`${sharedConfiguration.backendHost}/customer-portal/graphql`
		));
	}
	
	get productServices(): ApolloClient<any> {
		return this._productsServices || (this._productsServices = createApolloClient(
			`${sharedConfiguration.backendHost}/product/graphql`
		));
	}
	
	get tasksService(): ApolloClient<any> {
		return this._tasksService || (this._tasksService = createApolloClient(
			`${sharedConfiguration.backendHost}/tasks/graphql`
		));
	}
}

export const graphQlServices = new GraphQlServices();
