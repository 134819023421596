import React from "react";
import {Card, CardContent, CardHeader, Divider, IconButton, Stack, useTheme} from "@mui/material";
import {
	File03Icon, formatter, openNewTab, parseLocalDateTime, SbButton,
	SbTypography,
	SbTypographyRaw, sharedGraphQlServices, Trash02Icon
} from "@surebase/shared-component-library";
import {GET_MESSAGES_LINK_PRE_ASSIGNED_URL} from "../../../services/message-box/graphql/MessageBoxQueries";
import {MessageBox} from "../../../services/message-box/graphql/MessageBox";
import ReactHtmlParser from "html-react-parser";
import {grey} from "@mui/material/colors";

export interface MessageViewProps {
	readonly message: MessageBox.Message | undefined;
	readonly onRemoved: (message: MessageBox.Message) => void;
}

export function MessageView(props: MessageViewProps) {
	const theme = useTheme();
	const secondaryText = theme.palette.text.secondary;
	
	async function openLink(messageId: string, link: MessageBox.Link) {
		const response = await sharedGraphQlServices.messageBoxService.query<MessageBox.Queries>({
			query: GET_MESSAGES_LINK_PRE_ASSIGNED_URL,
			variables: {messageId: messageId, linkId: link.id}
		});
		
		const url = response.data.messagesLinkPreAssignedUrl?.downloadUrl;
		if (!url)
			throw new Error("Could not generate preassinged url");
		
		openNewTab(url);
	}
	
	if (!props.message)
		return <Card style={{flexGrow: "1"}}>Nothing</Card>;
	
	const message = props.message;
	const links = message.links ?? [];
	const messageCategory = formatter.enum(message.messageCategory);
	return <Card variant={"outlined"}>
		<CardHeader title={
			<Stack direction={"row"} gap={1} alignItems={"center"}>
				<File03Icon color={"primary"}/>
				<Stack flexGrow={1}>
					<SbTypographyRaw variant={"labelBadge"}>{
						message.senderPlatform}
					</SbTypographyRaw>
					<SbTypography variant={"body2"} color={secondaryText}>
						{messageCategory}
					</SbTypography>
				</Stack>
				<Stack direction={"row"} gap={1} alignItems={"center"}>
					<SbTypographyRaw variant={"body2"} color={secondaryText} style={{marginLeft: "auto"}}>
						{formatter.dateTime(parseLocalDateTime(props.message.creationDateTime), {
							year: "numeric",
							month: "short",
							day: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
							hour12: false
						})}
					</SbTypographyRaw>
					<IconButton onClick={() => {
						props.onRemoved(message);
					}}>
						<Trash02Icon/>
					</IconButton>
				</Stack>
			</Stack>
		} style={{backgroundColor: "#FFFFFF", padding: "12px 24px"}}/>
		<Divider color={grey[300]}/>
		<CardContent style={{padding: "24px"}}>
			<Stack flexWrap={"wrap"} justifyContent={"flex-start"} gap={2}>
				<SbTypographyRaw variant={"cardButtonTitle1"}>{message.header}</SbTypographyRaw>
				<Stack>
					<SbTypographyRaw variant={"bodyLarge"} color={secondaryText}>
						{message.subject}
					</SbTypographyRaw>
					<SbTypographyRaw variant={"bodyLarge"} color={secondaryText}>
						{ReactHtmlParser(message.body)}
					</SbTypographyRaw>
				</Stack>
				{links.length > 0 && (
					<div>
						{links.map((link, index) => (
							<SbButton key={index} ButtonProps={{
								size: "large",
								variant: "contained",
								color: "primary",
								style: {padding: "10px 18px", borderRadius: "4px"},
								onClick: () => openLink(message.id, link)
							}}>
								<SbTypography variant={"buttonLarge"}>{link.name}</SbTypography>
							</SbButton>
						))}
					</div>
				)}
			</Stack>
		</CardContent>
	</Card>;
}
