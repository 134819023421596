import React, {CSSProperties, ReactNode} from "react";
import {
	SbLinkButton,
	SbTypography,
	TranslationProvider
} from "@surebase/shared-component-library";
import {
	Card,
	CardActions, CardHeader,
	Divider,
	Stack,
	useTheme
} from "@mui/material";
import {Settings} from "../../global/Settings";
import {grey} from "@mui/material/colors";

export interface DashBoarQuickLinkCardProps {
	readonly heading: string;
	readonly headingIcon?: ReactNode;
	readonly bodyText: string;
	readonly buttonText: string;
	readonly linkTo: string;
	readonly style?: CSSProperties;
}

export function DashBoardQuickLinksCard(props: DashBoarQuickLinkCardProps) {
	const theme = useTheme();
	
	return <TranslationProvider name={Settings.languageModule}>
		<Card variant={"outlined"}
			style={{borderColor: grey[300], height: "100%", display: "flex", flexDirection: "column"}}>
			<CardHeader id={"horizontal-container1"}
				style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}}
				avatar={(
					<Stack id={"horizontal-container2"} gap={2} alignItems={"center"} direction={"row"}>
						{props.headingIcon && props.headingIcon}
						<SbTypography variant={"cardButtonTitle1"}>{props.heading}</SbTypography>
					</Stack>
				)}
			/>
			<Divider color={grey[300]}/>
			<CardActions style={{padding: "16px", gap: "16px", flexGrow: 1, display: "flex", flexDirection: "column"}}>
				<Stack gap={2} style={{width: "100%", flexGrow: 1, justifyContent: "space-between"}}>
					<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
						{props.bodyText}
					</SbTypography>
					<SbLinkButton to={props.linkTo}
						ButtonProps={{
							size: "large",
							variant: "contained",
							style: {padding: "10px 18px", borderRadius: "4px"}
						}}>
						<SbTypography variant={"buttonLarge"}>{props.buttonText}</SbTypography>
					</SbLinkButton>
				</Stack>
			</CardActions>
		</Card>
	</TranslationProvider>;
}
