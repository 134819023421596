import {DELETE_MESSAGES, GET_MESSAGE_BOX_DETAILS, GET_MESSAGES_FOR_USER, MARK_AS_READ} from "./MessageBoxQueries";
import {sharedGraphQlServices} from "@surebase/shared-component-library";
import {MessageBox} from "./MessageBox";

export class MessageBoxDataRepository {
	static async getMessageCount(): Promise<number> {
		const response = await sharedGraphQlServices.messageBoxService.query<MessageBox.Queries>(
			{
				query: GET_MESSAGE_BOX_DETAILS,
				variables: {},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			return 0;
		
		return response.data?.messageBoxDetails?.unreadCount ?? 0;
	}
	
	static async getMessagesForUser(afterCursor?: string | null): Promise<{
		messages: MessageBox.Message[];
		pageInfo?: MessageBox.PageInfo;
	}> {
		const pageSize = 50;
		const response = await sharedGraphQlServices.messageBoxService.query<MessageBox.Queries>(
			{
				query: GET_MESSAGES_FOR_USER,
				variables: {
					pageSize: pageSize,
					cursorAfter: afterCursor
				},
				fetchPolicy: "no-cache",
			}
		);
		
		if (response.error) {
			return {messages: []};
		}
		
		return {
			messages: response.data?.messagesForUser?.nodes ?? [],
			pageInfo: response.data?.messagesForUser?.pageInfo
		};
	}
	
	static async updateMessageReadState(messageId: string): Promise<boolean> {
		const response = await sharedGraphQlServices.messageBoxService.query<MessageBox.Queries>(
			{
				query: MARK_AS_READ,
				variables: {
					input: {
						messageIds: [messageId]
					}
				},
				fetchPolicy: "no-cache",
			}
		);
		
		if (response.error)
			return false;
		
		return response.data.markAsRead.mutationResult?.success ?? false;
	}
	
	static async deleteMessage(messageId: string): Promise<boolean> {
		const response = await sharedGraphQlServices.messageBoxService.query<MessageBox.Queries>(
			{
				query: DELETE_MESSAGES,
				variables: {input: {messageIds: [messageId]}},
				fetchPolicy: "no-cache",
			}
		);
		
		if (response.error)
			return false;
		
		return response.data.deleteMessages.mutationResult?.success ?? false;
	}
}
