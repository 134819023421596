import {gql} from "@apollo/client";

export const GET_CUSTOMER = gql`
	query GetCustomer {
		customers {
			nodes {
				id
				krn
				callName
				initials
				prefixes
				surname
				secondPartOfName
				birthDate
				street
				houseNumber
				houseNumberAddition
				city
				postalCode
				country
				telephoneNumber
				workPhoneNumber
				mobile
				email
				emailWork
				refType
				relationNumber
				collectionAccountIban
				party {
					entityType
					refKey
					... on Contact {
						callName
						initials
						personalTitles
						prefixes
						relationNumber
						salutation
						surname
					}
					... on Intermediary {
						refKey
						porIdBroker
					}
					
					... on Partner {
						birthDate
						email
						gender
						initials
						mobile
						prefixes
						salutationType
						surname
						titleType
						refKey
					}
					
					... on Child {
						entityType
						birthDate
						gender
						initials
						prefixes
						refType
						relationNumber
						surname
						refKey
					}
				}
			}
		}
	}`;

export const GET_CUSTOMER_SUMMARY = gql`
	query GetCustomerSummary {
		customers {
			nodes {
				id
				krn
				initials
				prefixes
				surname
				relationNumber
				party {
					entityType
					refKey
					... on Contact {
						relationNumber
					}
					... on Intermediary {
						porIdBroker
					}
				}
			}
		}
	}`;

